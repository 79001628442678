import {
  REQUEST_EMPLOYEES,
  RECEIVE_EMPLOYEES,
  REQUEST_EMPLOYEE,
  RECEIVE_EMPLOYEE
} from "../actions/employeeActions";

const newLocal = {
  employeeDetailId: "",
  employeesId: "",
  street: "",
  streetNumber: 0,
  postcode: "",
  city: "",
  country: "",
  emailWork: "",
  emailPrivate: "",
  phonePrivate: "",
  phoneWork: "",
  phoneMobile: "",
  pager: "",
  birthday: "",
  approbationDate: "",
  convention: "",
  entrance: "",
  exit: "",
  agbConfirmed: false,
  function: "",
  actTimeAsEnd: false,
  hidePrivate: false
};

const initialState = {
  isFetchingEmployees: false,
  isFetchingEmployee: false,
  allEmployees: [],
  employee: {
    employeesId: "",
    userId: "",
    user: {
      Id: "",
      UserName: "",
      NormalizedUserName: "",
      Email: "",
      NormalizedEmail: "",
      EmailConfirmed: false,
      PasswordHash: "",
      SecurityStamp: "",
      ConcurrencyStamp: "",
      PhoneNumber: "",
      PhoneNumberConfirmed: false,
      TwoFactorEnabled: false,
      LockoutEnd: "",
      LockoutEnabled: true,
      AccessFailedCount: 0
    },
    employeeDetail: newLocal,
    organizationUnit: {
      organizationUnitId: 0,
      organizationUnitName: "",
      subOrganizationUnits: "",
      parentOrganizationUnit: "",
      organization: "",
      workingTimeBlocks: "",
      clinicWideQualifications: "",
      personnelNumbers: "",
      employeeAdditionalDetailConfigs: ""
    },
    employeeAdditionalDetails: [],
    title: "",
    firstName: "",
    familyName: "",
    shiftName: "",
    partTimePercentage: "",
    partTimePercentageFrom: "",
    partTimePercentageUntil: "",
    workingDays: "",
    workingDaysFrom: "",
    workingDaysUntil: "",
    personnelNumbers: [],
    absenceEntries: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EMPLOYEES:
      return Object.assign({}, state, {
        isFetchingEmployees: true
      });
    case RECEIVE_EMPLOYEES:
      return Object.assign({}, state, {
        isFetchingEmployees: false,
        allEmployees: action.allEmployees
      });
    case REQUEST_EMPLOYEE:
      return Object.assign({}, state, {
        isFetchingEmployee: true
      });
    case RECEIVE_EMPLOYEE:
      if (!action.employee.employeeDetail) {
        action.employee.employeeDetail = newLocal;
      }
      return Object.assign({}, state, {
        isFetchingEmployee: false,
        employee: action.employee
      });
    default:
      return state;
  }
};

import React from 'react';
import { Jumbotron, Container, Card, Row, Col } from 'react-bootstrap';
import { Button, Form, Alert } from 'react-bootstrap';
import store from '../../../store';
import { recoverPassword } from '../../../actions/identityActions';
import Config from '../../../Config';

class RecoverPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            resetError: false,
            resetSuccess: false,
            config: new Config()
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <Jumbotron>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg={5}>
                            <Card>
                                <Card.Body>
                                    <h2>Password vergessen?</h2>
                                    <Form name="resetPassword" onSubmit={this.handleSubmit}>
                                        <Alert variant="success" show={this.state.resetSuccess}>
                                            Wir haben dir eine E-Mail gesendet mit weiteren Anweisungen.
                                        </Alert>
                                        <Alert variant="danger" show={this.state.resetError}>
                                            Es gab einen Fehler beim Zurücksetzen von deinem Passwort. Bitte überprüfe deine Zugangsdaten!
                                        </Alert>
                                        <Form.Group>
                                            <Form.Label>E-Mail-Adresse</Form.Label>
                                            <Form.Control type="email" name="email" value={this.state.email} required onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Button variant="primary" type="submit">
                                                Passwort zurücksetzen
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [ name ]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        store.dispatch(recoverPassword(this.state.email, window.location.origin)).then((response) => {
            if (!response.ok) {
                this.setState({
                    resetError: true
                });
            } else {
                this.setState({
                    resetSuccess: true
                });
            }
        });
    }
}

export default RecoverPassword;
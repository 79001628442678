export const listEmployeesRight = {
    id: 101,
    name: "listEmployees"
}
export const createEmployeesRight = {
    id: 103,
    name: "createEmployees"
}
export const editRolesRight = {
    id: 120,
    name: "editRoles"
}
export const editRoleRightsRight = {
    id: 121,
    name: "editRoleRights"
}
export const editEmployeeRolesRight = {
    id: 122,
    name: "editEmployeeRoles"
}
export const changePasswordRight = {
    id: 130,
    name: "changePassword"
}


export const addOwnAbsenceEntryRight = {
    id: 6001,
    name: "addOwnAbsenceEntry"
}
export const deleteAbsenceEntryRight = {
    id: 6002,
    name: "deleteAbsenceEntry"
}
export const editOwnAbsenceEntryRight = {
    id: 6003,
    name: "editOwnAbsenceEntry"
}
export const editAbsenceEntryRight = {
    id: 6004,
    name: "editAbsenceEntry"
}
export const addAbsenceEntryRight = {
    id: 6005,
    name: "addAbsenceEntry"
}
export const absenceEntriesViewRight = {
    id: 6006,
    name: "absenceEntriesView"
}
export const unfinishedAbsenceEntriesViewRight = {
    id: 6007,
    name: "unfinishedAbsenceEntriesView"
}

export function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[ index ].split("=");
        if (tmp[ 0 ] === parameterName) result = decodeURIComponent(tmp[ 1 ]);
    }
    return result;
}

export function passwordConfirm(pw1, pw2) {

    if (pw2 === '') {
        return true;
    }

    if (pw1 !== pw2) {
        return false;
    } else {
        return true;
    }

}
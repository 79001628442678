import moment from "moment";
import localization from "moment/locale/de";
import React from "react";
import Calendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { postAbsenceEntry } from "../../../../actions/absenceActions";
import store from "../../../../store";

var newEvent = {
  title: "Neuer Eintrag",
  start: null,
  end: null,
  comments: "",
  absenceEntryId: -1
};

function Event({ event }) {
  return <div />;
}

// function formatTime(dateString) {
//   let date = new Date(dateString);
//   let hoursString = "" + date.getHours();
//   let minuteString = "" + date.getMinutes();
//   if (hoursString.length === 1) {
//     hoursString = "0" + hoursString;
//   }
//   if (minuteString.length === 1) {
//     minuteString = "0" + minuteString;
//   }

//   return hoursString + ":" + minuteString;
// }

// function formatDate(dateString) {
//   let date = new Date(dateString);
//   var options = {
//     weekday: "long",
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit"
//   };

//   return date.toLocaleDateString("de-DE", options);
// }

//Lokalisierung des "moment" Objekts für Sprache und Ausgabeformat
moment()
  .locale("de", localization)
  .format("LLL");

//Localizer für Kalender aus lokalisiertem "moment" Objekt erstellen
const localizer = Calendar.momentLocalizer(moment);

//KalenderMessages Objekt für das überschreiben der Menüführungsbegriffe
const messages = {
  allDay: "ganztags",
  previous: "zurück",
  next: "vor",
  today: "Heute",
  month: "Monat",
  week: "Woche",
  day: "Tag",
  agenda: "Agenda",
  date: "Datum",
  time: "Zeit",
  event: "Ereignis", // Or anything you want
  showMore: total => `+ ${total} Ereignisse`
};

class MonthViewRender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: [],
      selectedAbsenceType: "",
      title: "",
      start: null,
      end: null,
      comment: "",
      absenceType: null,
      popupVisible: false,
      absenceEntryId: -1,
      events: [ { start: new Date(), end: new Date(), title: null } ],
      modalVisible: false,
      isEditable: false,
      absenceHistoryString: "",
      defaultDate: this.props.defaultDate
    };

    this.openState = false;
    this.closeModal = this.closeModal.bind(this);
    this.onSelecting = this.onSelecting.bind(this);
    this.onSubmitEvent = this.onSubmitEvent.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.hideInfo = this.hideInfo.bind(this);
    this.onAbsenceTypeChanged = this.onAbsenceTypeChanged.bind(this);
    this.handleSaveAbsenceType = this.handleSaveAbsenceType.bind(this);
  }

  //Kalender Rendern
  render() {
    return (
      <div className="Calendar">
        <Calendar
          toolbar={false}
          messages={messages}
          selectable={false}
          localizer={localizer}
          date={new Date(this.props.selectedYear + this.state.defaultDate)}
          defaultView="month"
          views={[ "month" ]}
          events={this.props.employeeAbsenceEntries}
          onSelectEvent={event => this.openModal(event)}
          style={{ height: "30vh" }}
          onSelectSlot={this.onSelecting}
          eventPropGetter={eventStyleGetter}
          components={{
            event: Event
          }}
          onNavigate={() => { }}
        />
      </div>
    );
  }

  onSelecting(e) {
    var date = e.start;
    date.setHours(date.getHours() + 8);
    newEvent.start = date;
    newEvent.end = date;
    newEvent.selectedAbsenceType = this.props.availableAbsenceTypes[ 0 ];
    newEvent.title = this.props.availableAbsenceTypes[ 0 ].absenceTypeDisplayString;
    newEvent.comments = "";
    newEvent.isEditable = true;
    newEvent.absenceHistoryString = "";
    this.openModal(newEvent);
  }

  closeModal() {
    this.setState({
      events: this.state.events,
      selectedEvent: null,
      modalVisible: false
    });
  }

  handleSaveAbsenceType(e) {
    e.preventDefault();
    var start =
      this.state.absenceEntryId === -1
        ? this.state.start.toJSON()
        : this.state.start;
    var end =
      this.state.absenceEntryId === -1
        ? this.state.end.toJSON()
        : this.state.end;
    store.dispatch(
      postAbsenceEntry(
        this.state.absenceEntryId,
        this.state.absenceType.absenceTypeId,
        start,
        end,
        this.state.comment
      )
    );
    this.hideInfo();
  }

  openModal(event) {
    var defaultAbsenceType;
    var absenceTypeId = event.selectedAbsenceType
      ? event.selectedAbsenceType.absenceTypeId
      : event.absenceType.absenceTypeId;
    this.props.availableAbsenceTypes.forEach(function (value) {
      if (value.absenceTypeId === absenceTypeId) defaultAbsenceType = value;
    });
    var isEditable = event.absenceStatus
      ? event.absenceStatus.isEditable
      : event.isEditable;
    this.setState({
      events: this.state.events,
      selectedEvent: event,
      start: event.start,
      end: event.end,
      absenceEntryId: event.absenceEntryId,
      title: event.title,
      absenceType: defaultAbsenceType,
      comment: event.comments,
      isEditable: isEditable,
      popupVisible: true,
      absenceHistoryString: event.absenceHistoryString
    });
  }

  onAbsenceTypeChanged(e) {
    this.setState({
      absenceType: e.value,
      title: e.value.absenceTypeDisplayString
    });
  }

  handleChangeStart(e) {
    this.setState({
      start: e.value
    });
  }

  handleChangeEnd(e) {
    this.setState({
      end: e.value
    });
  }

  hideInfo() {
    this.setState({
      popupVisible: false
    });
  }

  onCommentChange(e) {
    this.setState({
      comment: e.value
    });
  }

  onSubmitEvent() {
    console.log(
      this.state.start,
      this.state.end,
      this.state.title,
      this.state.newComment
    );
  }
}

function eventStyleGetter(event, start, end, isSelected) {
  var style = {
    backgroundColor: event.absenceType.absenceColorHex,
    borderRadius: "0px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block"
  };
  return {
    style: style
  };
}

function mapStateToProps(state) {
  const {
    availableAbsenceTypes,
    isFetchingAbsenceType,
    allAbsenceEntries,
    employeeAbsenceEntries,
    selectedYear
  } = state.absenceReducer;
  return {
    availableAbsenceTypes,
    isFetchingAbsenceType,
    allAbsenceEntries,
    employeeAbsenceEntries,
    selectedYear
  };
}

export default connect(mapStateToProps)(MonthViewRender);

import React, { Component } from 'react';
import { Button, Container, Jumbotron } from 'react-bootstrap';
import Table from "react-bootstrap/Table";
import { connect } from 'react-redux';
import { fetchOrganizationUnits } from "../../../actions/organizationUnitActions";
import { fetchPersonnelRoles, fetchRolesToEmployee, updateRolesToEmployee, deleteRolesToEmployee } from '../../../actions/personnelRolesActions';
import { getAllApplicationUsers } from '../../../actions/identityActions';
import store from '../../../store';
import RolesToEmployeeRows from './RolesToEmployeeRows';
import "../../core/Icons/Icons";


class RolesToEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            organizationUnit: [],
            editedRowData: undefined,
            roles:[],
            user:[]
            
        }

        
        
        this.deleteRoles = this.deleteRoles.bind(this);
        this.handleAddRolesToEmployee = this.handleAddRolesToEmployee.bind(this);

    }

    deleteRoles(status, data, neuerEintrag)
    {
        var applicationUsersId = data.applicationUsersId;

        if(!applicationUsersId)
            applicationUsersId = this.state.user[0].Id

        var from = data.from;
        var to = data.to;
        var organizationUnitsId = data.organizationUnitsId;
        var personnelRolesId = data.personnelRolesId;
        if(neuerEintrag)
        {
            store.dispatch(updateRolesToEmployee({applicationUsersId, from, organizationUnitsId, personnelRolesId, to }))
            .then(response => {
                var userOrganistationunitRolesList = this.state.data
                var lastIndex = userOrganistationunitRolesList.length - 1;
                userOrganistationunitRolesList[lastIndex] = response;
                this.setState({ 
                    data: userOrganistationunitRolesList 
                });
            })
        }
        else
        {
            var oldOrganizationUnitsId = data.oldOrganizationUnitsId;
            var oldPersonnelRolesId = data.oldPersonnelRolesId;
            if(status === "save")
            {
                store.dispatch(updateRolesToEmployee({applicationUsersId, from, organizationUnitsId, personnelRolesId, oldOrganizationUnitsId, oldPersonnelRolesId, to }))
                .then(response => {
                    var userOrganistationunitRolesList = this.state.data;
                    var index = 0;
                    for(var userOrganistationunitRoles of userOrganistationunitRolesList)
                    {
                        if(userOrganistationunitRoles.organizationUnitsId === oldOrganizationUnitsId &&  userOrganistationunitRoles.personnelRolesId === oldPersonnelRolesId)
                        {
                            break;
                        }
                        index = index +1;
                    }
                    userOrganistationunitRolesList[index] = response;
                    this.setState({
                        data: userOrganistationunitRolesList 
                    });
                })
            }
            else if (status === "delete")
            {
                store.dispatch(deleteRolesToEmployee({applicationUsersId, from, organizationUnitsId, personnelRolesId, oldOrganizationUnitsId, oldPersonnelRolesId, to}))
                .then(response => {
                    store.dispatch(fetchRolesToEmployee()).then(json => {
                        this.setState({ 
                            data: json 
                        });
                    })
                })
            }
        }
    }

    handleAddRolesToEmployee(event)
    {
        var data = this.state.data;
        data.push({neuerEintrag : true, organizationUnitsId: 1, personnelRolesId: 1, from: new Date()})
        this.setState({
            data: data
        })
    }

    componentDidMount()
    {
        store.dispatch(fetchOrganizationUnits()).then(json => {
            this.setState({
                organizationUnit: json.organizationUnits
            })
        });
        store.dispatch(fetchRolesToEmployee()).then(json => {
            this.setState({ 
                data: json 
            });
        })
        store.dispatch(fetchPersonnelRoles()).then(json => {
            this.setState({
                roles: json
            })
        })
        store.dispatch(getAllApplicationUsers()).then(json =>{
            this.setState({
                user: json
            })
        })
    }


    render() {
        return (
            <Jumbotron>
                <Container className="with-background">
                    <Table striped bordered hover className="responsive">
                        <thead>
                            <tr>
                                <th>Benutzer</th>
                                <th>OrganizationUnit</th>
                                <th>Rolle</th>
                                <th>Von</th>
                                <th>Bis</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((r) => (
                                <RolesToEmployeeRows key={"Cell "+ r.applicationUsersId + r.organizationUnitsId + r.personnelRolesId } user={this.state.user} organizationUnit={this.state.organizationUnit} roles={this.state.roles} data={r} neuerEintrag={r.neuerEintrag} onClick={this.deleteRoles}></RolesToEmployeeRows>
                            ))}                         
                        </tbody>
                    </Table>
                    <Button variant="success" type="button" value={this.state} onClick={this.handleAddRolesToEmployee}>Neuer Eintrag</Button>
                </Container>
            </Jumbotron>
        );
        
    }
    
    
}

function mapStateToProps(state) {
    const { organizationUnitReducer, identity } = state;
    const { organizationUnits } = state.organizationUnitReducer;
    return {
        organizationUnitReducer,
        identity,
        organizationUnits
    };
}

export default connect(mapStateToProps)(RolesToEmployee);








import notify from "devextreme/ui/notify";
import { history } from "./history";
import store from "../store";
import { setUser } from "../actions/identityActions";

export function authHeader() {
  // return authorization header with jwt token if logged in
  let user = JSON.parse(localStorage.getItem("user"));
  if (user != null) {
    return { "Content-Type": "application/json", Authorization: user.token };
  }
  return { "Content-Type": "application/json" };
}

export function handleResponse(response) {
  if (response.status === 200) {
    return response.text().then(function (text) {
      return text ? JSON.parse(text) : response;
    });
  } else {
    if (response.status === 401) {
      store.dispatch(
        setUser({
          UserName: null,
          Id: null,
          Token: null,
          Employee: null,
        })
      );
      history.push("/login");
      notify(
        "Ihr Token ist abgelaufen. Bitte loggen Sie sich erneut ein!",
        "error",
        5000
      );
      return response;
    } else {
      response.json().then((json) => notify(json.errorMessage, "error", 3000));
      return response;
    }
  }
}

import React from 'react';
import { Container, Jumbotron, Card } from 'react-bootstrap';
import { connect } from "react-redux";
import UserForm from '../Shared/forms/UserForm';

class Profile extends React.Component {
    render() {
        return (
            <Jumbotron>
                <Container>
                    <Card>
                        <Card.Body>
                            <UserForm user={this.props.identity.user} employee={this.props.identity.user.Employee} />
                        </Card.Body>
                    </Card>
                </Container>
            </Jumbotron>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity
    };
}

export default connect(mapStateToProps)(Profile);
import { Popup } from "devextreme-react";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterPanel,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
  StateStoring
} from "devextreme-react/data-grid";
import { Position } from "devextreme-react/popup";
import React from "react";
import { Container, Jumbotron } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchAvailableEmployees } from "../../../actions/employeeActions";
import { history } from "../../../helpers/history";
import store from "../../../store";

class EmployeeManagementTable extends React.Component {
  componentDidMount() {
    store.dispatch(fetchAvailableEmployees());
  }

  rowDisableEdit(e) {
    e.component.columnOption("command:edit", "visible", false);
  }

  rowClick(e) {
    if (e.key != null) {
      history.push("/Employee/EmployeeDetailsManagement/" + e.key.employeesId);
    }
  }

  render() {
    return (
      <Jumbotron>
        <Container className="with-background">
          <DataGrid
            dataSource={this.props.employeeReducer.allEmployees}
            id="employeeGridContainer"
            rowAlternationEnabled={true}
            showRowLines={false}
            onRowClick={this.rowClick}
            onContentReady={this.rowDisableEdit}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnHidingEnabled={true}
            columnAutoWidth={true}
          >
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={false} />
            <StateStoring
              enabled={true}
              type={"localStorage"}
              storageKey={"storage"}
            />
            <Paging defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[ 5, 10, 20 ]}
              showInfo={true}
            />
            <Editing
              mode={"popup"}
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
            >
              <Popup
                title={"Mitarbeiter"}
                showTitle={true}
                width={"90%"}
                height={"80%"}
              >
                <Position my={"top"} at={"top"} of={window} />
              </Popup>
            </Editing>
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Column
              key={0}
              dataField={"title"}
              visible={true}
              allowEditing={false}
            />
            <Column
              key={1}
              dataField={"firstName"}
              visible={true}
              allowEditing={false}
            />
            <Column
              key={2}
              dataField={"familyName"}
              visible={true}
              allowEditing={false}
            />
            <Column
              key={3}
              dataField={"birthday"}
              visible={false}
              allowEditing={false}
            />
            <Column
              key={4}
              dataField={"entrance"}
              visible={false}
              allowEditing={false}
            />
            <Column
              key={5}
              dataField={"city"}
              visible={false}
              allowEditing={false}
            />
            <Column
              key={6}
              dataField={"country"}
              visible={false}
              allowEditing={false}
            />
            <Column
              key={7}
              dataField={"postcode"}
              visible={false}
              allowEditing={false}
            />
            <Column
              key={8}
              dataField={"convention"}
              visible={false}
              allowEditing={false}
            />
            <Column
              key={9}
              dataField={"shiftName"}
              visible={false}
              allowEditing={false}
            />
          </DataGrid>
        </Container>
      </Jumbotron>
    );
  }
}

function mapStateToProps(state) {
  const { employeeReducer } = state;
  return {
    employeeReducer
  };
}

export default connect(mapStateToProps)(EmployeeManagementTable);

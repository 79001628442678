import React, { Component } from "react";
import { connect } from "react-redux";
import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import { Link } from "react-router-dom";
import store from "../../../../store";
import { postAbsenceEntry, fetchAllAbsenceEntriesWithUnfinishedAbsences } from "../../../../actions/absenceActions";
import { Jumbotron, Container } from "react-bootstrap";
import { getMainOrganizationUnit } from "../../../../helpers/organizationUnitHelper";

class UnfinishedAbsenceEntries extends Component {
    constructor(props) {
        super(props);

        this.buttons = this.buttons.bind(this);
        this.acceptAbsence = this.acceptAbsence.bind(this);
        this.calculateName = this.calculateName.bind(this);
    }

    componentWillMount() {
        store.dispatch(fetchAllAbsenceEntriesWithUnfinishedAbsences(this.props.identity.user.Id));
    }

    render() {
        return (
            <Jumbotron>
                <Container className="with-background table-align-middle">
                    <DataGrid
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        dataSource={this.props.unfinishedAbsenceEntries}
                        showColumnLines={false}
                    >
                        <Column
                            caption={"Vorname"}
                            calculateCellValue={this.calculateName}
                            dataField={"employee.firstName"}
                        />
                        <Column
                            caption={"Status"}
                            dataField={"title"}
                        />
                        <Column
                            caption={"Anfang"}
                            dataField={"start"}
                            dataType={'date'}
                            format={'dd.MM.yyyy'}
                        />
                        <Column
                            caption={"Ende"}
                            dataField={"end"}
                            dataType={'date'}
                            format={'dd.MM.yyyy'}
                        />
                        <Column
                            caption={""}
                            width={325}
                            dataField={"absenceEntryId"}
                            cellRender={this.buttons}
                        />
                    </DataGrid>
                </Container>
            </Jumbotron>
        );
    }

    buttons(props) {
        var selectedAbsenceEntry;
        this.props.unfinishedAbsenceEntries.forEach(function (item) {
            if (item.absenceEntryId === props.value) selectedAbsenceEntry = item;
        });
        if (!selectedAbsenceEntry)
            return (<div></div>)

        return (
            <React.Fragment>
                <div>
                    <Link style={{ marginRight: "5px" }} to={"UserArea/monthOverview/MonthOverviewAllEmployees/" + 0 + "/" + getMainOrganizationUnit(selectedAbsenceEntry.employee.organizationUnitMembershipTimespans) + "/" + selectedAbsenceEntry.start}>
                        <Button
                            text="zur Abwesenheit"
                            type="default"
                            stylingMode="contained"
                        />
                    </Link>

                    <Button
                        text="genehmigen"
                        type="success"
                        stylingMode="contained"
                        item={selectedAbsenceEntry}
                        onClick={this.acceptAbsence}
                    />
                </div>
            </React.Fragment>
        );
    }

    calculateName(e) {
        return e.employee.firstName + " " + e.employee.familyName;
    }

    acceptAbsence(e) {
        var selectedAbsenceEntry = e.component.option().item;

        store.dispatch(postAbsenceEntry(
            selectedAbsenceEntry.absenceEntryId,
            selectedAbsenceEntry.absenceType.absenceTypeId,
            selectedAbsenceEntry.start,
            selectedAbsenceEntry.end,
            "Antrag angenommen",
            selectedAbsenceEntry.employee.userId,
            this.props.identity.user.Id,
            -1,
            true,
            selectedAbsenceEntry.selectedOrganizationUnit,
            selectedAbsenceEntry.isHalfDay
        )).then(response => store.dispatch(fetchAllAbsenceEntriesWithUnfinishedAbsences((this.props.identity.user.Id))));
    }
}

function mapStateToProps(state) {
    const { unfinishedAbsenceEntries } = state.absenceReducer;
    const { identity } = state;
    return { unfinishedAbsenceEntries, identity };
}

export default connect(mapStateToProps)(UnfinishedAbsenceEntries);
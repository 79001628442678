import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import MonthOverviewAllEmployeesTableCell from "./MonthOverviewAllEmployeesTableCell";

class MonthOverviewAllEmployeesTableRow extends Component {
  constructor(props) {
    super(props);
    this.i = 0;
    this.state = {
      initialDate: this.props.initialDate
    };
  }
  render() {
    let row = [];
    // aus initialDate wird erneut der drittletzte Tag des letzten Monats berechnet
    let initialDate = new moment(this.state.initialDate)
      .subtract(1, "month")
      .endOf("month")
      .subtract(2, "days");
    // jede Zelle des betrachteten Zeitraums innerhalb der übergebenen Row würd gefüllt und die nötigen Props übergeben
    for (let i = 0; i < this.props.colCount; i++) {
      row.push(<MonthOverviewAllEmployeesTableCell cellDate={new moment(initialDate)} key={initialDate} colIndex={i} rowIndex={this.props.rowIndex} values={this.props.values} initialDate={this.state.initialDate} rowUser={this.props.rowUser} user={this.props.user} availableAbsenceTypes={this.props.availableAbsenceTypes} availableAbsenceStates={this.props.availableAbsenceStates} allAbsenceEntries={this.props.allAbsenceEntries} onCellClick={this.props.onCellClick} />);
      initialDate = initialDate.add(1, "days");
    }
    return (<tr>
      <th key={0}>{this.props.legend}</th>
      {row}
    </tr>);
  }
}

function mapStateToProps(state) {
    const { user } = state.identity;
    return {
      user
    };
  }

export default connect(mapStateToProps)(MonthOverviewAllEmployeesTableRow);
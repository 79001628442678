import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ORGANIZATIONUNITS = "REQUEST_ORGANIZATIONUNITS";
export function requestOrganizationUnits() {
  return {
    type: REQUEST_ORGANIZATIONUNITS
  };
}

export const RECEIVE_ORGANIZATIONUNITS = "RECEIVE_ORGANIZATIONUNITS";
export function receiveOrganizationUnits(json) {
  return {
    type: RECEIVE_ORGANIZATIONUNITS,
    organizationUnits: json
  };
}

export function fetchOrganizationUnits() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return function(dispatch) {
    dispatch(requestOrganizationUnits());
    return fetch(config.backendHost + "/OrganizationUnits/get", requestOptions)
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveOrganizationUnits(json)));
  };
}

import fetch from "cross-fetch";
import Config from "../Config";
import { DecryptPayload } from "../helpers/FGEncryption.js";
import { authHeader, handleResponse } from "../helpers/requestHelpers";
import store from "../store";

const config = new Config();

export const SET_REGISTRATION_STATUS = "SET_REGISTRATION_STATUS";
export function setRegistationStatus(json) {
  return {
    type: SET_REGISTRATION_STATUS,
    registrationStatus: json,
  };
}

export function register(user) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return function (dispatch) {
    return fetch(config.backendHost + "/users/register", requestOptions)
      .then((response) => handleResponse(response))
      .then((json) => dispatch(setRegistationStatus(json)));
  };
}

export function createEmployeeForUser() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return function (dispatch) {
    //let user = JSON.parse(localStorage.getItem("user"));
    return fetch(
      config.backendHost + "/users/createEmployeeForUser",
      requestOptions
    ).then((response) => handleResponse(response));
  };
}

export const SET_USER = "SET_USER";
export function setUser(json) {
  return {
    type: SET_USER,
    user: json,
  };
}

export const LOGOUT_USER = "LOGOUT_USER";
export function logoutUser() {
  window.localStorage.clear();
  return {
    type: LOGOUT_USER,
  };
}

export function login(username, password) {
  var stayLoggedIn = true;
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ username, password, stayLoggedIn }),
  };

  return function (dispatch) {
    return fetch(config.backendHost + "/users/authenticate", requestOptions)
      .then((response) => handleResponse(response))
      .then((json) => {
        if (json.token) {
          var result = json;
          result.PayLoad = JSON.parse(
            DecryptPayload(result.payLoad, result.token)
          );
          console.log(result.Payload);
          dispatch(
            setUser({
              UserName: result.PayLoad.UserName,
              Id: result.PayLoad.Id,
              token: result.token,
              RefreshToken: result.PayLoad.RefreshToken,
              Employee: JSON.parse(result.PayLoad.Employee),
            })
          );
          console.log(
            "user",
            {
              UserName: result.payLoad.UserName,
              Id: result.PayLoad.Id,
              token: result.token,
              RefreshToken: result.PayLoad.RefreshToken,
              Employee: JSON.parse(result.PayLoad.Employee),
            },
            result
          );
          setTimeout(getNewToken(), 3000);
          return json;
        }

        return false;
      });
  };
}

export function update(user) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return function (dispatch) {
    return fetch(
      config.backendHost + "/users/update",
      requestOptions
    ).then((response) => handleResponse(response));
  };
}

export function recoverPassword(email, url) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: "email=" + email + "&url=" + encodeURI(url),
  };

  return function (dispatch) {
    return fetch(
      config.backendHost + "/mail/recoverPassword",
      requestOptions
    ).then((response) => {
      return response;
    });
  };
}

export function resetPassword(email, token, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body:
      "email=" +
      email +
      "&token=" +
      encodeURIComponent(token) +
      "&password=" +
      password,
  };

  return function (dispatch) {
    return fetch(
      config.backendHost + "/users/resetPassword",
      requestOptions
    ).then((response) => {
      return response;
    });
  };
}

export function authenticate(username, password) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ username, password }),
  };

  return function (dispatch) {
    return fetch(
      config.backendHost + "/users/authenticate",
      requestOptions
    ).then((response) => {
      return response;
    });
  };
}

export const SET_USERHASRIGHT = "SET_USERHASRIGHT";
export function setUserHasRight(json) {
  return {
    type: SET_USERHASRIGHT,
    right: json,
  };
}

export function getUserHasRight(user, rightsId, organizationUnitsId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      authUser: {
        Id: user.Id,
        UserName: user.UserName,
        Token: user.Token,
      },
      rightsId: rightsId,
      organizationUnitsId: organizationUnitsId,
    }),
  };
  return function (dispatch) {
    return fetch(config.backendHost + "/users/hasRight", requestOptions)
      .then((response) => handleResponse(response))
      .then((json) => {
        dispatch(setUserHasRight(json));
      });
  };
}

export function getAllApplicationUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return function (dispatch) {
    return fetch(
      config.backendHost + "/users/getAllApplicationUsers",
      requestOptions
    ).then((response) => handleResponse(response));
  };
}

export function getNewToken() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  console.log("requestOptions", requestOptions);
  return function (dispatch) {
    return fetch(config.backendHost + "/users/getNewToken", requestOptions)
      .then((response) => handleResponse(response))
      .then((result) => {
        if (result) {
          console.log(result);
          let user = JSON.parse(localStorage.getItem("user"));
          let tokenTimerVal = result.TokenTimeInMinutes * 0.9 * 60000;
          console.log(user, tokenTimerVal);
          if (user && tokenTimerVal > 0) {
            store.dispatch(
              setUser({
                UserName: user.UserName,
                Id: user.Id,
                Token: result.Token,
                Employee: user.Employee,
                RefreshToken: user.RefreshToken,
              })
            );
            window.setTimeout(getNewToken(), tokenTimerVal);
          }
        } else {
        }
      });
  };
}

export function getNewRefreshToken(refreshToken) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ refreshToken: refreshToken }),
  };
  return function (dispatch) {
    return fetch(
      config.backendHost + "/Users/getNewRefreshToken",
      requestOptions
    )
      .then((response) => handleResponse(response))
      .then((result) => {
        if (result) {
          let user = JSON.parse(localStorage.getItem("user"));
          store.dispatch(
            setUser({
              UserName: user.UserName,
              id: user.Id,
              Token: result.Token,
              Employee: user.Employee,
              RefreshToken: result.RefreshToken,
            })
          );
        }
      });
  };
}

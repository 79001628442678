import { SET_REGISTRATION_STATUS, SET_USER, LOGOUT_USER, SET_USERHASRIGHT } from "../actions/identityActions";
import { history } from "../helpers/history";

let user = JSON.parse(localStorage.getItem('user'));
const initialState = {
    user: user ? user : {},
    registrationStatus: {
        Errors: [],
        Succeeded: null
    }
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_REGISTRATION_STATUS:
		return Object.assign({}, state, {
			registrationStatus: action.registrationStatus
        })
        case SET_USER:
		localStorage.setItem('user', JSON.stringify(action.user));
		return Object.assign({}, state, {
			user: action.user
		})
		case SET_USERHASRIGHT:
		return Object.assign({}, state, {
			[action.right.name]: action.right.value
		})
		case LOGOUT_USER:
		localStorage.removeItem('user');
		history.push('/login');
		return Object.assign({}, state, {
			user: {}
		})
		default:
		return state
	}
}
import {
    REQUEST_ORGANIZATIONUNITS,
    RECEIVE_ORGANIZATIONUNITS
} from "../actions/organizationUnitActions";

const initialState = {
    isFetchingOrganizationUnit: false,
    organizationUnits: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ORGANIZATIONUNITS:
            return Object.assign({}, state, {
                isFetchingOrganizationUnit: true
            });
        case RECEIVE_ORGANIZATIONUNITS:
            return Object.assign({}, state, {
                isFetchingOrganizationUnit: false,
                organizationUnits: action.organizationUnits
            });

        default:
            return state;
    }
};
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Container, Jumbotron, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../../core/Icons/Icons";
import store from "../../../store";
import { fetchAllAbsenceEntriesWithUnfinishedAbsences } from "../../../actions/absenceActions";
import { connect } from "react-redux";
import {
  listEmployeesRight,
  createEmployeesRight,
  absenceEntriesViewRight,
  unfinishedAbsenceEntriesViewRight,
  editRolesRight,
  editRoleRightsRight,
  editEmployeeRolesRight,
} from "../../../helpers/Rights";
import { getUserHasRight } from "../../../actions/identityActions";

const iconStyle = {
  fontSize: "100px",
  display: "block",
  margin: "0px auto 20px auto",
  position: "relative",
};

const cardStyle = {
  height: "100%",
};

const numberStyle = {
  position: "absolute",
  fontSize: "20px",
  top: "25px",
  right: "-20px",
  background: "#000",
  width: "40px",
  height: "40px",
  color: "#fff",
  borderRadius: "40px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const cardBodyStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-around",
};

class Navigation extends React.Component {
  componentWillMount() {
    store.dispatch(
      fetchAllAbsenceEntriesWithUnfinishedAbsences(this.props.identity.user.Id)
    );
    store.dispatch(
      getUserHasRight(this.props.identity.user, listEmployeesRight.id, -1)
    );

    store.dispatch(
      getUserHasRight(this.props.identity.user, createEmployeesRight.id, -1)
    );

    store.dispatch(
      getUserHasRight(this.props.identity.user, absenceEntriesViewRight.id, -1)
    );

    store.dispatch(
      getUserHasRight(
        this.props.identity.user,
        unfinishedAbsenceEntriesViewRight.id,
        -1
      )
    );

    store.dispatch(
      getUserHasRight(this.props.identity.user, editRolesRight.id, -1)
    );

    store.dispatch(
      getUserHasRight(this.props.identity.user, editRoleRightsRight.id, -1)
    );

    store.dispatch(
      getUserHasRight(this.props.identity.user, editEmployeeRolesRight.id, -1)
    );
  }

  render() {
    console.log(this.props.identity);
    return (
      <Jumbotron fluid={true}>
        <Container>
          <Row>
            <Col xs={12} sm={6} md={4} className="mb-4">
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="calendar-alt" style={iconStyle} />
                  <Card.Title>Kalender</Card.Title>
                  <Card.Text>Kalender eigene Abwesenheiten</Card.Text>
                  <LinkContainer
                    to={"UserArea/monthOverview/MonthOverviewAllEmployees/" + 1}
                  >
                    <Button className="btn-light">zum Kalender</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[absenceEntriesViewRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="calendar-alt" style={iconStyle} />
                  <Card.Title>Monatsübersicht</Card.Title>
                  <Card.Text>
                    Kalender Abwesenheiten aller Mitarbeiten
                  </Card.Text>
                  <LinkContainer to="UserArea/monthOverview/MonthOverviewAllEmployees">
                    <Button className="btn-light">zum Kalender</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="mb-4">
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="calendar" style={iconStyle} />
                  <Card.Title>Jahresansicht</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptas, facilis.
                  </Card.Text>
                  <LinkContainer to="yearview">
                    <Button className="btn-light">zur Jahresansicht</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[unfinishedAbsenceEntriesViewRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card
                className="text-white bg-primary"
                style={cardStyle}
                bg={
                  this.props.unfinishedAbsenceEntries.length > 0
                    ? "danger"
                    : "primary"
                }
              >
                <Card.Img variant="top" />
                <Card.Body style={cardBodyStyle}>
                  <div style={iconStyle}>
                    <FontAwesomeIcon icon="envelope" />
                    <p style={numberStyle}>
                      <span>{this.props.unfinishedAbsenceEntries.length}</span>
                    </p>
                  </div>
                  <Card.Title>Offene Abwesenheiten</Card.Title>
                  <Card.Text>
                    Anzeige aller offenen Abwesenheiten mit Schnellbearbeitung.
                  </Card.Text>
                  <LinkContainer to="unfinishedAbsenceEntries">
                    <Button className="btn-light">
                      Zu offenen Abwesenheiten
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[createEmployeesRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Img variant="top" />
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="user-plus" style={iconStyle} />
                  <Card.Title>Neuer Benutzer</Card.Title>
                  <Card.Text>Neuen Benutzer anlegen.</Card.Text>
                  <LinkContainer to="register">
                    <Button className="btn-light">
                      Neuen Benutzer anlegen
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[listEmployeesRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Img variant="top" />
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="user" style={iconStyle} />
                  <Card.Title>Mitarbeiter</Card.Title>
                  <Card.Text>Übersicht aller Mitarbeiter.</Card.Text>
                  <LinkContainer to="/Employee/EmployeeManagementTable">
                    <Button className="btn-light">
                      zur Mitarbeiterverwaltung
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[editRolesRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Img variant="top" />
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="user" style={iconStyle} />
                  <Card.Title>Rollen</Card.Title>
                  <Card.Text>Rollen anlegen oder bearbeiten.</Card.Text>
                  <LinkContainer to="/Employee/Roles/Roles">
                    <Button className="btn-light">zur Rollenverwaltung</Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[editRoleRightsRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Img variant="top" />
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="user" style={iconStyle} />
                  <Card.Title>Konfiguration</Card.Title>
                  <Card.Text>
                    Einzelne Rechte bestimmten Rollen zuordnen.
                  </Card.Text>
                  <LinkContainer to="Configuration">
                    <Button className="btn-light">
                      zur Rollenkonfiguration
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              className={
                this.props.identity[editEmployeeRolesRight.name]
                  ? "mb-4"
                  : "mb-4 d-none"
              }
            >
              <Card className="text-white bg-primary" style={cardStyle}>
                <Card.Img variant="top" />
                <Card.Body style={cardBodyStyle}>
                  <FontAwesomeIcon icon="user" style={iconStyle} />
                  <Card.Title>Mitarbeiterrollen</Card.Title>
                  <Card.Text>
                    Mitarbeiter einzelne Rollen in bestimmten
                    Organisationeinheiten zuweisen.
                  </Card.Text>
                  <LinkContainer to="/Employee/Roles/RolesToEmployee">
                    <Button className="btn-light">
                      Mitarbeiter zu Rollen zuweisen
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    );
  }
}

function mapStateToProps(state) {
  const { unfinishedAbsenceEntries } = state.absenceReducer;
  const { identity } = state;
  return {
    identity,
    unfinishedAbsenceEntries,
  };
}

export default connect(mapStateToProps)(Navigation);

import React, { Component } from "react";
import moment from "moment";
import localization from "moment/locale/de";
import Table from "react-bootstrap/Table";
import "./Presences.scss";

//Wochentagsabkürzungen auf deutsch
moment()
  .local("de", localization)
  .format("LLL");

class PresencesCell extends Component {
  render() {
    const { value, colored } = this.props;
    let colorScheme = "";
    if (colored)
      colorScheme =
        (value > 0 ? "green" : value === 0 ? "yellow" : "red") + "Cell";
    //Farbe nach Vorzeichen
    else colorScheme = "";
    return <td className={colorScheme}>{value}</td>;
  }
}

class PresenceRow extends Component {
  constructor(props) {
    super(props);
    this.i = 0;
    this.startDate = this.props.startDate;
  }

  render() {
    let row = [];
    let currentDate = this.props.startDate;

    for (let i = 0; i < this.props.colCount; i++) {
      row.push(
        <PresencesCell
          colored
          cellDate={currentDate}
          key={currentDate}
          colIndex={i}
          rowIndex={this.props.rowIndex}
          value={this.props.values[i]}
        />
      );
      currentDate = currentDate.add(1, "days");
    }
    return (
      <tr>
        <th key={0}>{this.props.legend}</th>
        {row}
      </tr>
    );
  }
}

class PresencesTable extends Component {
  render() {
    let legend = [];
    let legend2 = [];
    let legend3 = [];
    let data = [];

    {
      let colNeeded = this.props.colCount;
      let e = this.props.startDate;
      while (colNeeded > 0) {
        let colsCurrMonth =
          moment(e)
            .endOf("month")
            .date() -
          e.date() +
          1;
        colsCurrMonth = colsCurrMonth > colNeeded ? colNeeded : colsCurrMonth;
        colNeeded -= colsCurrMonth;
        let currMonthName = e.format("MMM YY");
        e.add(colsCurrMonth, "days");
        legend.push(
          <th key={currMonthName} colSpan={colsCurrMonth}>
            {currMonthName}
          </th>
        );
      }
    }

    for (let i = 0; i < this.props.colCount; i++) {
      legend2.push(<th key={i}>{this.props.topLegend2[i]}</th>);
      legend3.push(<th key={i}>{this.props.topLegend3[i]}</th>);
    }

    for (let i = 0; i < this.props.rowCount; i++) {
      data.push(
        <PresenceRow
          rowIndex={i}
          colCount={this.props.colCount}
          startDate={this.props.startDate}
          key={i}
          legend={this.props.leftLegend[i]}
          values={this.props.data[i]}
        />
      );
    }

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th key={1} />
            {legend}
          </tr>
          <tr>
            <th key={2} />
            {legend2}
          </tr>
          <tr>
            <th key={3} />
            {legend3}
          </tr>
        </thead>
        <tbody>{data}</tbody>
      </Table>
    );
  }
}

class Presences extends Component {
  state = {
    startDate: moment(),
    leftLegend: [" abwesende OA:", "FA:", "Summen:"],
    topLegend: [],
    topLegend2: [],
    topLegend3: [],
    data: [
      [
        -1,
        3,
        4,
        1,
        3,
        3,
        2,
        2,
        1,
        2,
        3,
        3,
        2,
        2,
        1,
        1,
        1,
        3,
        2,
        1,
        1,
        1,
        1,
        2,
        3,
        3,
        2,
        1,
        1,
        2
      ],
      [
        1,
        11,
        14,
        5,
        9,
        13,
        4,
        4,
        11,
        11,
        11,
        11,
        13,
        7,
        5,
        11,
        11,
        9,
        9,
        11,
        6,
        5,
        13,
        8,
        11,
        10,
        11,
        7,
        7,
        9
      ],
      []
    ]
  };

  constructor(props) {
    super(props);
    const { data } = this.state;
    for (
      let i = 0, day = moment(this.state.startDate);
      i < data[0].length;
      i++, day = day.add(1, "day")
    ) {
      this.state.topLegend.push(day.format("MM"));
      this.state.topLegend2.push(day.format("DD"));
      this.state.topLegend3.push(day.format("ddd"));
      data[2][i] = data[0][i] + data[1][i];
    }
  }

  render() {
    const { leftLegend, topLegend, topLegend2, topLegend3, data } = this.state;
    return (
      <PresencesTable
        startDate={this.state.startDate}
        rowCount={3}
        colCount={data[0].length}
        leftLegend={leftLegend}
        topLegend={topLegend}
        topLegend2={topLegend2}
        topLegend3={topLegend3}
        data={data}
      />
    );
  }
}

export default Presences;

import React, { Component } from "react";
import { connect } from "react-redux";
import { giveEntry, isWeekend } from "./MonthOverviewAllEmployees";
import { Popover } from "devextreme-react";
import moment from "moment";

class MonthOverviewAllEmployeesTableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: null,
      cellDate: this.props.cellDate,
      initialDate: this.props.initialDate,
      user: this.props.user,
      availableAbsenceTypes: this.props.availableAbsenceTypes,
      availableAbsenceStates: this.props.availableAbsenceStates,
      allAbsenceEntries: this.props.allAbsenceEntries,
      data: this.props.data,
      entry1PopoverVisible: false,
      entry2PopoverVisible: false
    };

    this.showEntry1Popover = this.showEntry1Popover.bind(this);
    this.hideEntry1Popover = this.hideEntry1Popover.bind(this);
    this.showEntry2Popover = this.showEntry2Popover.bind(this);
    this.hideEntry2Popover = this.hideEntry2Popover.bind(this);

  }
  render() {
    // falls an dem ausgewählten Tag eine Abwesenheit vorhanden ist, wird diese in die Zelle eingetragen. Ansonsten ist die Zelle leer
    var entries = giveEntry(this.props.values, this.state.cellDate);
    var entriy1Id = entries[ 0 ] ? 'entry1' + entries[ 0 ].absenceEntryId + this.props.colIndex : "";
    var entriy2Id = entries[ 1 ] ? 'entry2' + entries[ 1 ].absenceEntryId + this.props.colIndex : "";

    var colorFirstEntry1 = entries.length > 0 ? entries[ 0 ].absenceType.absenceColorHex : (isWeekend(new Date(this.state.cellDate)) ? "#ccc" : "#fff");
    var colorSecondEntry1 = entries.length > 0 ? entries[ 0 ].absenceStatus.absenceStatusColorHex : (isWeekend(new Date(this.state.cellDate)) ? "#ccc" : "#fff");
    var divStyleEntry1 = {
      position: "relative",
      display: "flex",
      flex: "1 1",
      top: "0px",
      left: "0px",
      bottom: "0px",
      right: "0px",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    };

    var colorFirstEntry2 = entries.length > 1 ? entries[ 1 ].absenceType.absenceColorHex : (isWeekend(new Date(this.state.cellDate)) ? "#ccc" : "#fff");
    var colorSecondEntry2 = entries.length > 1 ? entries[ 1 ].absenceStatus.absenceStatusColorHex : (isWeekend(new Date(this.state.cellDate)) ? "#ccc" : "#fff");
    var divStyleEntry2 = {
      position: "relative",
      display: "flex",
      flex: "1 1",
      top: "0px",
      left: "0px",
      bottom: "0px",
      right: "0px",
      height: "100%",
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    };

    var divStyleEntry1Background = {
      display: 'block',
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      zIndex: '0',
      opacity: (!entries[ 0 ] && isWeekend(new Date(this.state.cellDate))) ? "1" : "0.5",
      background: "repeating-linear-gradient(" +
        "45deg," +
        colorFirstEntry1 + " 5px," +
        colorFirstEntry1 + " 20px," +
        colorSecondEntry1 + " 20px," +
        colorSecondEntry1 + " 23px" +
        ")"
    }

    var divStyleEntry2Background = {
      display: 'block',
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      zIndex: '0',
      opacity: (!entries[ 1 ] && isWeekend(new Date(this.state.cellDate))) ? "1" : "0.5",
      background: "repeating-linear-gradient(" +
        "45deg," +
        colorFirstEntry2 + " 5px," +
        colorFirstEntry2 + " 20px," +
        colorSecondEntry2 + " 20px," +
        colorSecondEntry2 + " 23px" +
        ")"
    }

    return (
      <td style={{ padding: "0", position: "relative" }}>
        <div style={{ display: "flex", position: "absolute", top: "0", left: "0", right: "0", bottom: "0" }}>
          {
            entries.length === 0 &&

            <div style={divStyleEntry1} onClick={() => this.props.onCellClick(null, this.state.cellDate, this.props.rowUser)}>
              <span style={divStyleEntry1Background}></span>
              <span style={{ textAlign: "center", position: 'relative', zIndex: '1' }}>+</span>

            </div>
          }
          {
            entries.length > 0 &&
            <>
              <div id={entriy1Id} onMouseEnter={this.showEntry1Popover} onMouseLeave={this.hideEntry1Popover} style={divStyleEntry1} onClick={() => this.props.onCellClick(entries[ 0 ], this.state.cellDate, this.props.rowUser)}>
                <span style={divStyleEntry1Background}></span>
                <span style={{ textAlign: "center", position: 'relative', zIndex: '1' }}>{entries[ 0 ] ? entries[ 0 ].absenceType.absenceTypeAbbreviationString : ""}</span>
              </div>
              {entriy1Id && <Popover
                target={'#' + entriy1Id}
                position={'top'}
                visible={this.state.entry1PopoverVisible}
              >
                {popoverContent(entries[ 0 ])}
              </Popover>}
              <div id={entriy2Id} onMouseEnter={this.showEntry2Popover} onMouseLeave={this.hideEntry2Popover} style={divStyleEntry2} onClick={() => this.props.onCellClick(entries[ 1 ], this.state.cellDate, this.props.rowUser)}>
                <span style={divStyleEntry2Background}></span>
                <span style={{ textAlign: "center", position: 'relative', zIndex: '1' }}>{entries[ 1 ] ? entries[ 1 ].absenceType.absenceTypeAbbreviationString : "+"}</span>
              </div>
              {entriy2Id && <Popover
                target={'#' + entriy2Id}
                position={'top'}
                visible={this.state.entry2PopoverVisible}
              >
                {popoverContent(entries[ 1 ])}
              </Popover>}
            </>
          }
        </div>
      </td>);
  }

  showEntry1Popover() {
    this.setState({
      entry1PopoverVisible: true
    });
  }

  hideEntry1Popover() {
    this.setState({
      entry1PopoverVisible: false
    });
  }

  showEntry2Popover() {
    this.setState({
      entry2PopoverVisible: true
    });
  }

  hideEntry2Popover() {
    this.setState({
      entry2PopoverVisible: false
    });
  }
}

export function popoverContent(data) {
  let period = '';
  period = moment(data.start).format('DD.MM.YYYY');
  period += data.start !== data.end ? ' - ' + moment(data.end).format('DD.MM.YYYY') : '';

  let type = data.absenceType.absenceTypeDisplayString;
  let status = data.absenceStatus.statusName;

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      ({status}) {type}: {period}
    </span>
  );
}

function mapStateToProps(state) {
  const { user } = state.identity;
  return {
    user
  };
}

export default connect(mapStateToProps)(MonthOverviewAllEmployeesTableCell);

import React from "react";
import Legend from "../../Shared/legend/Legend";
import {
  fetchAvailableAbsenceTypes,
  fetchAvailableAbsenceStates,
  fetchEmployeeAbsenceEntries
} from "../../../../actions/absenceActions";
import store from "../../../../store";
import { connect } from 'react-redux';
import { Jumbotron, Container } from "react-bootstrap";


class MonthOverview extends React.Component {
  componentWillMount() {
    store.dispatch(fetchAvailableAbsenceTypes());
    store.dispatch(fetchEmployeeAbsenceEntries(this.props.user.Id));
    store.dispatch(fetchAvailableAbsenceStates());
  }

  render() {
    return (
      <div>
        <Jumbotron>
          <Container className="with-background" >
            <Legend />
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.identity;
  return { user };
}

export default connect(mapStateToProps)(MonthOverview);

import React from "react";
import { connect } from 'react-redux';
import "./Legend.scss";

class Legend extends React.Component {
    render() {
        let legendEntries = [];
        this.props.availableAbsenceTypes &&
            this.props.availableAbsenceTypes.map(x => legendEntries.push(
                <span className="badge" style={{ backgroundColor: x.absenceColorHex, color: "white", margin: "1px" }} key={x.absenceTypeId}>
                    {x.absenceTypeDisplayString}({x.absenceTypeAbbreviationString})
            </span>
            ))

        return (
            <div className="header">
                {legendEntries}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { availableAbsenceTypes } = state.absenceReducer;
    return { availableAbsenceTypes };
}

export default connect(mapStateToProps)(Legend);

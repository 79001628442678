export function getMainOrganizationUnit(organizationUnitList) {
  if (organizationUnitList === null) {
    return null;
  }
  if (organizationUnitList.length > 0) {
    var returnOrganizationUnit = organizationUnitList[0].organizationUnitsId;

    for (var organizationUnit of organizationUnitList) {
      if (
        organizationUnit.isMain &&
        new Date(organizationUnit.from) < new Date() &&
        (organizationUnit.to === null ||
          new Date(organizationUnit.to) > new Date())
      ) {
        returnOrganizationUnit = organizationUnit.organizationUnitsId;
        break;
      }
    }
  } else {
    return null;
  }

  return returnOrganizationUnit;
}

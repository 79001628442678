class Config {
  constructor() {
    this.backendHost = null;

    //Anpassen

    const hostname = window && window.location && window.location.hostname;
    if (hostname === "localhost") {
      this.backendHost = "http://localhost:5000";
    } else {
      this.backendHost = "http://" + hostname + "/b";
    }
  }
}

export default Config;

import React, { Component } from "react";
import { Jumbotron, Container } from "react-bootstrap";
import store from "../../../store";
import {
  fetchPersonnelRoles,
  updatePersonnelRole,
  insertPersonnelRole,
  deletePersonnelRole
} from "../../../actions/personnelRolesActions";
import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      editedRowData: undefined
    };

    this.handleRemove = this.handleRemove.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleInit = this.handleInit.bind(this);
  }

  render() {
    return (
      <Jumbotron>
        <Container className="with-background">
          <DataGrid
            showColumnHeaders={false}
            dataSource={this.state.roles}
            editing={{
              allowUpdating: true,
              allowDeleting: true,
              allowAdding: true,
              mode: "cell",
              useIcons: true
            }}
            onInitialized={this.handleInit}
            onRowRemoved={this.handleRemove}
            onRowUpdated={this.handleUpdate}
            onRowInserting={this.handleInsert}
          >
            <Column
              dataField={"name"}
              allowEditing={true}
              validationRules={[ { type: "required" } ]}
            />
          </DataGrid>
        </Container>
      </Jumbotron>
    );
  }

  handleInit(e) {
    e.component.beginCustomLoading();
    store.dispatch(fetchPersonnelRoles()).then(json => {
      this.setState({ roles: json });
      e.component.endCustomLoading();
    });
  }

  handleUpdate(e) {
    e.component.beginCustomLoading();
    store.dispatch(updatePersonnelRole(e.data)).then(response => {
      this.handleInit(e);
      e.component.endCustomLoading();
    });
  }

  handleRemove(e) {
    e.component.beginCustomLoading();
    store.dispatch(deletePersonnelRole(e.data)).then(response => {
      this.handleInit(e);
      e.component.endCustomLoading();
    });
  }

  handleInsert(e) {
    e.component.beginCustomLoading();
    store.dispatch(insertPersonnelRole(e.data)).then(response => {
      this.handleInit(e);
      e.component.endCustomLoading();
    });
  }
}

export default Roles;

import React from "react";
import { Col, Container, Jumbotron, Row, Card } from "react-bootstrap";
import RegisterForm from "./RegisterForm/RegisterForm";

class Register extends React.Component {
  render() {
    return (
      <Jumbotron>
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={5}>
              <Card>
                <Card.Body>
                  <h2>Neuen Benutzer anlegen</h2>
                  <RegisterForm />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    );
  }
}

export default Register;

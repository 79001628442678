import React from "react";
import { Container, Jumbotron, Card } from "react-bootstrap";
import UserForm from "../Shared/forms/UserForm";
import store from "../../../store";
import {
  fetchEmployee,
  getUserFromEmployee
} from "../../../actions/employeeActions";
import { connect } from "react-redux";
import { changePasswordRight } from "../../../helpers/Rights";

class EmployeeDetailsManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined,
      employeeData: undefined
    };
  }

  componentWillMount() {
    const { id } = this.props.match.params;
    if (id) {
      store.dispatch(fetchEmployee(id)).then(data => {
        this.setState({ employeeData: data });
        store
          .dispatch(
            getUserFromEmployee(
              this.props.identity.user,
              changePasswordRight.id,
              data.employee.userId
            )
          )
          .then(json => {
            this.setState({
              user: json
            });
          });
      });
    }
  }

  render() {
    return (
      <Jumbotron>
        <Container>
          <Card>
            <Card.Body>
              {this.state.employeeData && (
                <UserForm
                  user={this.state.user}
                  employee={this.state.employeeData.employee}
                ></UserForm>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Jumbotron>
    );
  }
}

function mapStateToProps(state) {
  const { identity } = state;
  return {
    identity
  };
}

export default connect(mapStateToProps)(EmployeeDetailsManagement);

import { userService } from "../services/userService";
import { alertActions } from "./alertActions";

export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  // LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  // LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  // LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  // LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  GETALLROLES_REQUEST: "USERS_GETALLROLES_REQUEST",
  GETALLROLES_SUCCESS: "USERS_GETALLROLES_SUCCESS",
  GETALLROLES_FAILURE: "USERS_GETALLROLES_FAILURE",

  GETUSERROLES_REQUEST: "USERS_GETUSERROLES_REQUEST",
  GETUSERROLES_SUCCESS: "USERS_GETUSERROLES_SUCCESS",
  GETUSERROLES_FAILURE: "USERS_GETUSERROLES_FAILURE",

  GETUSERRIGHTS_REQUEST: "USERS_GETUSERRIGHTS_REQUEST",
  GETUSERRIGHTS_SUCCESS: "USERS_GETUSERRIGHTS_SUCCESS",
  GETUSERRIGHTS_FAILURE: "USERS_GETUSERRIGHTS_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE"
};

export const userActions = {
  // login,
  // logout,
  getAll,
  // register,
  getAllRoles,
  getUserRoles,
  getUserRights
};

function getAll() {
  return dispatch => {
    dispatch(request());

    userService.getAll().then(
      users => dispatch(success(users)),
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getAllRoles() {
  return dispatch => {
    dispatch(request());

    userService.getAllRoles().then(
      roles => dispatch(success(roles)),
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALLROLES_REQUEST };
  }
  function success(roles) {
    return { type: userConstants.GETALLROLES_SUCCESS, roles };
  }
  function failure(error) {
    return { type: userConstants.GETALLROLES_FAILURE, error };
  }
}

function getUserRoles(user) {
  return dispatch => {
    dispatch(request());

    userService.getUserRoles(user).then(
      userroles => dispatch(success(userroles)),
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GETUSERROLES_REQUEST };
  }
  function success(userroles) {
    return { type: userConstants.GETUSERROLES_SUCCESS, userroles };
  }
  function failure(error) {
    return { type: userConstants.GETUSERROLES_FAILURE, error };
  }
}

function getUserRights(user) {
  return dispatch => {
    dispatch(request());

    userService.getUserRights(user).then(
      userrights => dispatch(success(userrights)),
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GETUSERRIGHTS_REQUEST };
  }
  function success(userrights) {
    return { type: userConstants.GETUSERRIGHTS_SUCCESS, userrights };
  }
  function failure(error) {
    return { type: userConstants.GETUSERRIGHTS_FAILURE, error };
  }
}

// export function register(username, password, role) {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({ username: username, password: password, role: role })
//   };

//   return function (dispatch) {
//     return fetch(config.backendHost + "/users/register", requestOptions).then(
//       user => {
//         dispatch(success(user));
//       }
//     );

//     // userService.register(username, password)
//     //     .then(
//     //         user => {
//     //             dispatch(success(user));
//     //         },
//     //         error => {
//     //             dispatch(failure(error));
//     //             dispatch(alertActions.error(error));
//     //         }
//     //     );
//   };

//   function request(user) {
//     return { type: userConstants.REGISTER_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.REGISTER_SUCCESS, user };
//   }
//   function failure(error) {
//     return { type: userConstants.REGISTER_FAILURE, error };
//   }
// }

import React from "react";
import { Container, Row, Col, Button, Jumbotron } from "react-bootstrap";
import MonthViewRender from "./MonthViewRender";
import Legend from "../../Shared/legend/Legend";
import { connect } from "react-redux";

import {
  fetchAllAbsenceEntries,
  fetchAvailableAbsenceTypes,
  fetchAvailableAbsenceStates,
  SET_YEAROVERVIEW,
  fetchEmployeeAbsenceEntries
} from "../../../../actions/absenceActions";
import store from "../../../../store";
class YearView extends React.Component {
  constructor(props) {
    super(props);
    this.janStr = "-01-01T00:00:00";
    this.febStr = "-02-01T00:00:00";
    this.marStr = "-03-01T00:00:00";
    this.aprStr = "-04-01T00:00:00";
    this.mayStr = "-05-01T00:00:00";
    this.junStr = "-06-01T00:00:00";
    this.julStr = "-07-01T00:00:00";
    this.augStr = "-08-01T00:00:00";
    this.sepStr = "-09-01T00:00:00";
    this.oktStr = "-10-01T00:00:00";
    this.novStr = "-11-01T00:00:00";
    this.decStr = "-12-01T00:00:00";
    this.state = {
      selectedYear: new Date().getUTCFullYear()
    };
    store.dispatch({
      type: SET_YEAROVERVIEW,
      selectedYear: new Date().getUTCFullYear()
    });
    this.onClickNextYear = this.onClickNextYear.bind(this);
    this.onClickPreviousYear = this.onClickPreviousYear.bind(this);
  }

  componentWillMount() {
    store.dispatch(fetchAvailableAbsenceTypes());
    store.dispatch(fetchAllAbsenceEntries());
    store.dispatch(fetchEmployeeAbsenceEntries(this.props.user.Id));
    store.dispatch(fetchAvailableAbsenceStates());
  }

  render() {
    return (
      <Jumbotron>
        <Container className="with-background">
          <Row>
            <Col xs>
              <Button
                variant="secondary"
                size="sm"
                active
                onClick={this.onClickPreviousYear}
              >
                Jahr zurück
              </Button>
            </Col>
            <Col xs={6}>
              <Legend />
            </Col>
            <Col xs>
              <Button
                variant="secondary"
                size="sm"
                active
                onClick={this.onClickNextYear}
              >
                Jahr vor
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <div>Januar {this.state.selectedYear} </div>
              <MonthViewRender defaultDate={this.janStr} />
            </Col>
            <Col lg={3}>
              <div>Februar {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.febStr} />
            </Col>
            <Col lg={3}>
              <div>März {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.marStr} />
            </Col>
            <Col lg={3}>
              <div>April {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.aprStr} />
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <div>Mai {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.mayStr} />
            </Col>
            <Col lg={3}>
              <div>Juni {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.junStr} />
            </Col>

            <Col lg={3}>
              <div>Juli {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.julStr} />
            </Col>

            <Col lg={3}>
              <div>August {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.augStr} />
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <div>September {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.sepStr} />
            </Col>
            <Col lg={3}>
              <div>Oktober {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.oktStr} />
            </Col>
            <Col lg={3}>
              <div>November {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.novStr} />
            </Col>
            <Col lg={3}>
              <div>Dezember {this.state.selectedYear}</div>
              <MonthViewRender defaultDate={this.decStr} />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    );
  }

  onClickPreviousYear() {
    var newYear = this.state.selectedYear - 1;
    store.dispatch({ type: SET_YEAROVERVIEW, selectedYear: newYear });
    this.setState({ selectedYear: newYear });
  }

  onClickNextYear() {
    var newYear = this.state.selectedYear + 1;
    store.dispatch({ type: SET_YEAROVERVIEW, selectedYear: newYear });
    this.setState({ selectedYear: newYear });
  }
}

function mapStateToProps(state) {
  const { user } = state.identity;
  return { user };
}

export default connect(mapStateToProps)(YearView);

import notify from "devextreme/ui/notify";
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateEmployee, updateOrganizationUnitMembershipTimespans } from '../../../../actions/employeeActions';
import { fetchOrganizationUnits } from '../../../../actions/organizationUnitActions';
import store from '../../../../store';
import { fetchDaysOfAbsence } from '../../../../actions/absenceActions';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { update, setUser } from "../../../../actions/identityActions";
import { createEmployeeForUser } from "../../../../actions/identityActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../core/Icons/Icons";

class UserForm extends React.Component {

    constructor(props) {
        super(props);

        let user = undefined;
        let employee = undefined;
        let organizationUnitMembershipTimespans = []
    

        if (this.props.user) {
            user = {
                user: {
                    ...this.props.user,
                    Password: '',
                    PasswordConfirm: ''
                }
            }
        }

       

        if (this.props.employee) {
            employee = this.setEmployee(this.props.employee)
            if(this.props.employee.organizationUnitMembershipTimespans)
            {
                organizationUnitMembershipTimespans=this.props.employee.organizationUnitMembershipTimespans
            }
        }

        this.state = {
            ...user,
            ...employee,
            newOrganization: false,
            buttonStyle: "",
            newOrganizationUnitMembershipTimespan: {
                organizationUnitsId: 1,
                from: null,
                to: null,
                isMain: false,
                organizationUnitMembershipTimespansId: -1
            },
            organizationUnitMembershipTimespans :organizationUnitMembershipTimespans,
            errors: {
                password: false,
                passwordErrorInfo: []
            }
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOrganizationUnitMembershipTimespans = this.handleOrganizationUnitMembershipTimespans.bind(this);
        this.handleNewOrganizationUnitMembershipTimespans = this.handleNewOrganizationUnitMembershipTimespans.bind(this);
        this.clickCreateEmployee = this.clickCreateEmployee.bind(this);
        this.setEmployee = this.setEmployee.bind(this);
        this.handleAddOrganizationUnitMembershipTimespans = this.handleAddOrganizationUnitMembershipTimespans.bind(this);
        this.handleChangeToAddOrganizationUnit = this.handleChangeToAddOrganizationUnit.bind(this);
        this.handleChangeStartToAddOrganizationUnit = this.handleChangeStartToAddOrganizationUnit.bind(this);
        this.handleChangeEndToAddOrganizationUnit = this.handleChangeEndToAddOrganizationUnit.bind(this);
        this.checkBoxOnValueChanged = this.checkBoxOnValueChanged.bind(this);
        this.handleNewToDate = this.handleNewToDate.bind(this);
        this.deleteOrganizationUnitMembershipTimespans = this.deleteOrganizationUnitMembershipTimespans.bind(this);
    }

    setEmployee(employee) {
        return {
            mondayChecked: (employee.workingDays & 1) === 1,
            tuesdayChecked: (employee.workingDays & 2) === 2,
            wednesdayChecked: (employee.workingDays & 4) === 4,
            thursdayChecked: (employee.workingDays & 8) === 8,
            fridayChecked: (employee.workingDays & 16) === 16,
            saturdayChecked: (employee.workingDays & 32) === 32,
            sundayChecked: (employee.workingDays & 64) === 64,
            employee: {
                userId: employee.userId ? employee.userId : '',
                employeesId: employee.employeesId ? employee.employeesId : '',
                title: employee.title ? employee.title : '',
                firstName: employee.firstName ? employee.firstName : '',
                familyName: employee.familyName ? employee.familyName : '',
                birthday: employee.employeeDetail.birthday ? employee.employeeDetail.birthday : '',
                street: employee.employeeDetail.street ? employee.employeeDetail.street : '',
                streetNumber: employee.employeeDetail.streetNumber ? employee.employeeDetail.streetNumber : '',
                postcode: employee.employeeDetail.postcode ? employee.employeeDetail.postcode : '',
                city: employee.employeeDetail.city ? employee.employeeDetail.city : '',
                country: employee.employeeDetail.country ? employee.employeeDetail.country : '',
                emailWork: employee.employeeDetail.emailWork ? employee.employeeDetail.emailWork : '',
                emailPrivate: employee.employeeDetail.emailPrivate ? employee.employeeDetail.emailPrivate : '',
                phoneWork: employee.employeeDetail.phoneWork ? employee.employeeDetail.phoneWork : '',
                phonePrivate: employee.employeeDetail.phonePrivate ? employee.employeeDetail.phonePrivate : '',
                phoneMobile: employee.employeeDetail.phoneMobile ? employee.employeeDetail.phoneMobile : '',
                pager: employee.employeeDetail.pager ? employee.employeeDetail.pager : '',
                approbationDate: employee.employeeDetail.approbationDate ? employee.employeeDetail.approbationDate : '',
                convention: employee.employeeDetail.convention ? employee.employeeDetail.convention : '',
                entrance: employee.employeeDetail.entrance ? employee.employeeDetail.entrance : '',
                exit: employee.employeeDetail.exit ? employee.employeeDetail.exit : '',
                agbConfirmed: employee.employeeDetail.agbConfirmed,
                function: employee.employeeDetail.function ? employee.employeeDetail.function : '',
                actTimeAsEnd: employee.employeeDetail.actTimeAsEnd,
                hidePrivate: employee.employeeDetail.hidePrivate,
                organizationUnit: employee.organizationUnit,
                partTimePercentage: employee.partTimePercentage ? employee.partTimePercentage : '',
                partTimePercentageFrom: employee.partTimePercentageFrom ? employee.partTimePercentageFrom : '',
                partTimePercentageUntil: employee.partTimePercentageUntil ? employee.partTimePercentageUntil : '',
                personnelNumbers: employee.personnelNumbers ? employee.personnelNumbers : '',
                workingDays: employee.workingDays,
                workingDaysFrom: employee.workingDaysFrom ? employee.workingDaysFrom : '',
                workingDaysUntil: employee.workingDaysUntile ? employee.workingDaysUntile : '',
                daysOfAbsence: 0,
            }
        }


    }



    componentDidMount() {
        const { employee } = this.state;

        store.dispatch(fetchOrganizationUnits());

        if (employee) {
            store.dispatch(fetchDaysOfAbsence(this.props.employee.employeesId)).then(data =>
                this.setState({
                    employee: {
                        ...employee,
                        daysOfAbsence: data.daysOfAbsence
                    }
                }));
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (!state.user && props.user) {
            return {
                user: {
                    ...props.user,
                    Password: '',
                    PasswordConfirm: ''
                },
                employee: state.employee
            }
        }
        return null;

    }

    handleChange(event) {
        var { name, value, type, checked } = event.target;
        const { employee } = this.state;
        if (type === 'checkbox') {
            if (name === 'mondayChecked') {
                this.setState({ mondayChecked: checked });
                return;
            } else if (name === 'tuesdayChecked') {
                this.setState({ tuesdayChecked: checked });
                return;
            } else if (name === 'wednesdayChecked') {
                this.setState({ wednesdayChecked: checked });
                return;
            } else if (name === 'thursdayChecked') {
                this.setState({ thursdayChecked: checked });
                return;
            } else if (name === 'fridayChecked') {
                this.setState({ fridayChecked: checked });
                return;
            } else if (name === 'saturdayChecked') {
                this.setState({ saturdayChecked: checked });
                return;
            } else if (name === 'sundayChecked') {
                this.setState({ sundayChecked: checked });
                return;
            } else {
                value = checked;
            }
        }

        this.setState({
            employee: {
                ...employee,
                [ name ]: value
            }
        });
    }

    handleChangeUser(event) {
        var { name, value } = event.target;
        const { user } = this.state;


        if (name === 'Password') {
            if (value !== user.PasswordConfirm && user.PasswordConfirm !== '') {
                this.setState({
                    errors: {
                        password: true
                    }
                });
            } else {
                this.setState({
                    errors: {
                        password: false
                    }
                });
            }
        }

        if (name === 'PasswordConfirm') {
            if (value !== user.Password && user.Password !== '') {
                this.setState({
                    errors: {
                        password: true
                    }
                });
            } else {
                this.setState({
                    errors: {
                        password: false
                    }
                });
            }
        }


        this.setState({
            user: {
                ...user,
                [ name ]: value
            }
        });

    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.props.employee) {
            var { employee } = this.state;

            let workingDays = 0;

            if (this.state.mondayChecked) {
                workingDays += 1;
            }
            if (this.state.tuesdayChecked) {
                workingDays += 2;
            }
            if (this.state.wednesdayChecked) {
                workingDays += 4;
            }
            if (this.state.thursdayChecked) {
                workingDays += 8;
            }
            if (this.state.fridayChecked) {
                workingDays += 16;
            }
            if (this.state.saturdayChecked) {
                workingDays += 32;
            }
            if (this.state.sundayChecked) {
                workingDays += 64;
            }

            employee.workingDays = workingDays;
        }

        if (this.props.user) {
            const { user } = this.state;
            var userData = {};
            userData.Id = user.Id;
            userData.UserName = user.UserName;
            userData.Email = userData.UserName;
            userData.Password = user.Password;
            userData.PasswordConfirm = user.PasswordConfirm;

            if (userData.Password !== userData.PasswordConfirm) {
                userData.Password = '';
                userData.PasswordConfirm = '';
            }
        }
        if (this.props.user && this.props.employee) {
            store.dispatch(updateEmployee(employee)).then(response => {

                if (response.status === 200) {
                    this.handleOrganizationUnitMembershipTimespans()
                    store.dispatch(update(userData)).then((response) => {
                        if (response.status !== 200) {
                            this.setState({
                                errors: {
                                    password: true,
                                    passwordErrorInfo: response
                                }
                            });

                            notify("Mitarbeiter konnte nicht gespeichert werden", "error", 2000);
                        } else {
                            notify("Mitarbeiter erfolgreich gespeichert", "success", 2000);
                        }
                    }
                    );

                }
                else {
                    notify("Mitarbeiter konnte nicht gespeichert werden", "error", 2000);
                }
            });
        } else if (this.props.user && !this.props.employee) {
            store.dispatch(update(userData)).then((response) => {

                if (response) {
                    this.setState({
                        errors: {
                            password: true,
                            passwordErrorInfo: response
                        }
                    });

                    notify("Mitarbeiter konnte nicht gespeichert werden", "error", 2000);
                } else {
                    notify("Mitarbeiter erfolgreich gespeichert", "success", 2000);
                }
            });
        } else if (!this.props.user && this.props.employee) {
            store.dispatch(updateEmployee(employee)).then(response => {
                this.handleOrganizationUnitMembershipTimespans()
                if (response.Succeeded === true) {
                    notify("Mitarbeiter erfolgreich gespeichert", "success", 2000);
                }
                else {
                    notify("Mitarbeiter konnte nicht gespeichert werden", "error", 2000);
                }
            });
        }


    }


    handleOrganizationUnitMembershipTimespans() {
        var organizationUnitMembershipTimespans = this.state.organizationUnitMembershipTimespans
        var obj = []
        for (var wert of organizationUnitMembershipTimespans) {
            var object = { employeesId: this.props.employee.employeesId, from: wert.from, to: wert.to, isMain: wert.isMain, organizationUnitMembershipTimespansId: wert.organizationUnitMembershipTimespansId, organizationUnitsId: wert.organizationUnitsId }
            obj.push(object)

        }
        store.dispatch(updateOrganizationUnitMembershipTimespans(obj)).then(response => {
           this.setState({
                organizationUnitMembershipTimespans: response
           })
        })
    }



    handleNewOrganizationUnitMembershipTimespans(event) {
        this.setState({
            newOrganization: !this.state.newOrganization
        })
        if (this.setState)
            this.setState({ buttonStyle: "hidden" })
        else
            this.setState({ buttonStyle: "" })
    }

    handleAddOrganizationUnitMembershipTimespans() {
        this.setState({
            newOrganization: !this.state.newOrganization
        })

        if (this.setState.newOrganization)
            this.setState({ buttonStyle: "hidden" })
        else
            this.setState({ buttonStyle: "" })

        var organizationUnitMembershipTimespansList = this.state.organizationUnitMembershipTimespans
        var newOrganizationUnitMembershipTimespan = this.state.newOrganizationUnitMembershipTimespan
        var organizationUnitMembershipTimespansId = this.state.newOrganizationUnitMembershipTimespan.organizationUnitMembershipTimespansId - 1

        if(newOrganizationUnitMembershipTimespan.isMain)
        {
            for (var organizationUnitMembershipTimespans of organizationUnitMembershipTimespansList) {
                if (organizationUnitMembershipTimespans.isMain) {
                    organizationUnitMembershipTimespans.isMain = false
                }
            }
        }

        for (var organizationUnit of this.props.organizationUnits) {
            if (parseInt(newOrganizationUnitMembershipTimespan.organizationUnitsId) === parseInt(organizationUnit.organizationUnitId)) {
                var obj = {
                    employeesId: this.props.employee.employeesId,
                    from: newOrganizationUnitMembershipTimespan.from,
                    to: newOrganizationUnitMembershipTimespan.to,
                    organizationUnitsId: newOrganizationUnitMembershipTimespan.organizationUnitsId,
                    OrganizationUnit: organizationUnit,
                    isMain: newOrganizationUnitMembershipTimespan.isMain,
                    organizationUnitMembershipTimespansId: organizationUnitMembershipTimespansId
                }                
                organizationUnitMembershipTimespansList.push(obj);
            }
        }

        this.setState({
            newOrganizationUnitMembershipTimespan: {
                organizationUnitsId: 1,
                from: null,
                to: null,
                isMain: false,
                organizationUnitMembershipTimespansId: organizationUnitMembershipTimespansId
            },
            organizationUnitMembershipTimespans: organizationUnitMembershipTimespansList
        })
    }

    handleChangeToAddOrganizationUnit(event) {
        var { value, name } = event.target
        const { newOrganizationUnitMembershipTimespan } = this.state;
        
        if(name === "isMain")
            value = true;

        this.setState({
            newOrganizationUnitMembershipTimespan: {
                ...newOrganizationUnitMembershipTimespan,
                [ name ]: value
            }
        })
    }

    handleChangeStartToAddOrganizationUnit(e) {
        const { newOrganizationUnitMembershipTimespan } = this.state;
        this.setState({
            newOrganizationUnitMembershipTimespan: {
                ...newOrganizationUnitMembershipTimespan,
                from: e.value
            }
        })
    }
    handleChangeEndToAddOrganizationUnit(e) {
        const { newOrganizationUnitMembershipTimespan } = this.state;
        this.setState({
            newOrganizationUnitMembershipTimespan: {
                ...newOrganizationUnitMembershipTimespan,
                to: e.value
            }
        })
    }


    clickCreateEmployee(e) {
        let user = JSON.parse(localStorage.getItem("user"));
        store.dispatch(createEmployeeForUser())
            .then(result => {
                this.setState({ employee: this.setEmployee(result) });
                store.dispatch(setUser({
                    UserName: user.UserName,
                    Id: user.Id,
                    Token: user.Token,
                    Employee: result
                }));
            });
    }

    handleNewToDate(event) {
        var { name, value } = event.target;

        var organizationUnitMembershipTimespansList = this.state.organizationUnitMembershipTimespans

        for (var organizationUnitMembershipTimespans of organizationUnitMembershipTimespansList) {
            if (organizationUnitMembershipTimespans.organizationUnitMembershipTimespansId === name) {
                organizationUnitMembershipTimespans.to = value;
            }
        }
        this.setState({
            organizationUnitMembershipTimespans: organizationUnitMembershipTimespansList
        })
    }

    checkBoxOnValueChanged(event) {
        var { name } = event.target;

        var organizationUnitMembershipTimespansList = this.state.organizationUnitMembershipTimespans

        for (var organizationUnitMembershipTimespans of organizationUnitMembershipTimespansList) {
            if (organizationUnitMembershipTimespans.isMain) {
                organizationUnitMembershipTimespans.isMain = false
            }
            if (organizationUnitMembershipTimespans.organizationUnitMembershipTimespansId === parseInt(name)) {
                organizationUnitMembershipTimespans.isMain = true;
            }
        }
        this.setState({
            organizationUnitMembershipTimespans: organizationUnitMembershipTimespansList
        })
    }

    deleteOrganizationUnitMembershipTimespans(event) {
        var { value } = event.target;

        var organizationUnitMembershipTimespansList = this.state.organizationUnitMembershipTimespans
        for (var organizationUnitMembershipTimespans of organizationUnitMembershipTimespansList) {
            if (organizationUnitMembershipTimespans.organizationUnitMembershipTimespansId === parseInt(value)) {
                organizationUnitMembershipTimespans.to = new Date();
                
            }
        }

        this.setState({
            organizationUnitMembershipTimespans: organizationUnitMembershipTimespansList
        })
    }

    render() {
        return (
            <Form name="form" onSubmit={this.handleSubmit}>
                {this.state.user && <fieldset>
                    <Form.Group>
                        <Form.Label>Benutzername</Form.Label>
                        <Form.Control type="email" name="UserName" value={this.state.user.UserName} required onChange={this.handleChangeUser} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Neues Passwort</Form.Label>
                        <Form.Control type="password" name="Password" value={this.state.user.Password} onChange={this.handleChangeUser} isInvalid={this.state.errors.password} />
                        <Form.Control.Feedback type="invalid">
                            <ul>
                                {this.state.errors.passwordErrorInfo && this.state.errors.passwordErrorInfo.map((error, i) => {
                                    return <li key={i}>{error.Description}</li>
                                })}
                            </ul>
                        </Form.Control.Feedback>
                    </Form.Group>
                    <PasswordStrengthMeter password={this.state.user.Password} />
                    <Form.Group>
                        <Form.Label>Neues Passwort wiederholen</Form.Label>
                        <Form.Control type="password" name="PasswordConfirm" value={this.state.user.PasswordConfirm} onChange={this.handleChangeUser} isInvalid={this.state.errors.password} />
                    </Form.Group>
                </fieldset>}
                {this.props.employee == null && this.props.user && <fieldset>
                    <Form.Group>
                        <Button variant="primary" type="button" onClick={this.clickCreateEmployee}>
                            Mitarbeiter für diesen User anlegen
                        </Button>
                    </Form.Group>
                </fieldset>}
                {this.props.employee && this.props.user && <hr />}
                {this.props.employee && <fieldset>
                    <Form.Group controlId="title">
                        <Form.Label>Titel</Form.Label>
                        <Form.Control type="text" name="title" value={this.state.employee.title} required onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="firstName">
                        <Form.Label>Vorname</Form.Label>
                        <Form.Control type="text" name="firstName" value={this.state.employee.firstName} required onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="familyName">
                        <Form.Label>Nachname</Form.Label>
                        <Form.Control type="text" name="familyName" value={this.state.employee.familyName} required onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="birthday">
                        <Form.Label>Geburtstag</Form.Label>
                        <Form.Control type="date" name="birthday" value={this.state.employee.birthday} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="street">
                        <Form.Label>Straße</Form.Label>
                        <Form.Control type="text" name="street" value={this.state.employee.street} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="streetNumber">
                        <Form.Label>Hausnummer</Form.Label>
                        <Form.Control type="text" name="streetNumber" value={this.state.employee.streetNumber} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="postcode">
                        <Form.Label>PLZ</Form.Label>
                        <Form.Control type="text" name="postcode" value={this.state.employee.postcode} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="city">
                        <Form.Label>Stadt</Form.Label>
                        <Form.Control type="text" name="city" value={this.state.employee.city} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="country">
                        <Form.Label>Land</Form.Label>
                        <Form.Control type="text" name="country" value={this.state.employee.country} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="emailWork">
                        <Form.Label>E-Mail (Arbeit)</Form.Label>
                        <Form.Control type="email" name="emailWork" value={this.state.employee.emailWork} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="emailPrivate">
                        <Form.Label>E-Mail (Privat)</Form.Label>
                        <Form.Control type="email" name="emailPrivate" value={this.state.employee.emailPrivate} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="phoneWork">
                        <Form.Label>Telefon (Arbeit)</Form.Label>
                        <Form.Control type="tel" name="phoneWork" value={this.state.employee.phoneWork} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="phonePrivate">
                        <Form.Label>Telefon (Privat)</Form.Label>
                        <Form.Control type="tel" name="phonePrivate" value={this.state.employee.phonePrivate} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="phoneMobile">
                        <Form.Label>Mobil</Form.Label>
                        <Form.Control type="tel" name="phoneMobile" value={this.state.employee.phoneMobile} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="pager">
                        <Form.Label>Pager</Form.Label>
                        <Form.Control type="tel" name="pager" value={this.state.employee.pager} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="approbationDate">
                        <Form.Label>Approbationsdatum</Form.Label>
                        <Form.Control type="date" name="approbationDate" value={this.state.employee.approbationDate} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="convention">
                        <Form.Label>Tarifvertrag</Form.Label>
                        <Form.Control type="text" name="convention" value={this.state.employee.convention} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="entrance">
                        <Form.Label>Eintrittsdatum</Form.Label>
                        <Form.Control type="date" name="entrance" value={this.state.employee.entrance} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="exit">
                        <Form.Label>Austrittsdatum</Form.Label>
                        <Form.Control type="date" name="exit" value={this.state.employee.exit} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="function">
                        <Form.Label>Funktion</Form.Label>
                        <Form.Control type="text" name="function" value={this.state.employee.function} onChange={this.handleChange} />
                    </Form.Group>
                    <table className="table">
                        <thead>
                            <tr>
                                <th sm="auto"><Form.Label>Abteilung</Form.Label></th>
                                <th sm="auto"><Form.Label>Bis</Form.Label></th>
                                <th sm="auto"><Form.Label>Main</Form.Label></th>
                                <th></th>
                            </tr>
                    </thead>
                    <tbody>
                    {this.state.organizationUnitMembershipTimespans.map((ou) => {
                        if (ou.to === null || new Date(ou.to) > new Date()) {
                            return (<tr key={ou.OrganizationUnit.organizationUnitName}>
                                        <td sm="auto">
                                            <Form.Control type="text" value={ou.OrganizationUnit.organizationUnitName} disabled></Form.Control>
                                        </td>
                                        <td sm="auto">
                                            <Form.Control name={ou.organizationUnitMembershipTimespansId} type="date" placeholder="bis" onChange={this.handleNewToDate} value={ou.to ? ou.to : ""} />
                                        </td>
                                        <td>
                                            <Form.Check type='checkbox' checked={ou.isMain} name={ou.organizationUnitMembershipTimespansId} onChange={this.checkBoxOnValueChanged}></Form.Check>
                                        </td>
                                        <td align="center">
                                            <Button variant="danger" type="button" value={ou.organizationUnitMembershipTimespansId} disabled={ou.isMain} onClick={this.deleteOrganizationUnitMembershipTimespans}>
                                                <FontAwesomeIcon icon="trash" style={{ fontSize: "20" }} />
                                            </Button>
                                        </td>
                                    </tr>)
                        } else {
                            return '';
                        }
                    })}
                    </tbody>
                    </table>
                    <Form.Group>

                        <Form.Group>
                            <Button variant="success" type="button" hidden={this.state.buttonStyle} onClick={this.handleNewOrganizationUnitMembershipTimespans}>Organisation hinzufügen</Button>
                        </Form.Group>
                        {this.state.newOrganization &&
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th>Neue Organisation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="12">
                                            <Form.Control controlid="newOrganizationUnitMembershipTimespansSelectBox" as="select" name="organizationUnitsId" value={this.state.employee.organizationUnit && this.state.employee.organizationUnit.organizationUnitId} onChange={this.handleChangeToAddOrganizationUnit} required>
                                            {this.props.organizationUnitReducer.organizationUnits.map((ou) => {
                                                return (<option key={'ou' + ou.organizationUnitId} value={ou.organizationUnitId}>{ou.organizationUnitName}</option>)
                                            })}
                                            </Form.Control>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control name="from" type="date" placeholder="von" required onChange={this.handleChangeToAddOrganizationUnit} value={this.state.newOrganizationUnitMembershipTimespan.from ? this.state.newOrganizationUnitMembershipTimespan.from : ""}/>
                                        </td>
                                        <td>
                                            <Form.Control name="to" type="date" placeholder="bis" onChange={this.handleChangeToAddOrganizationUnit} value={this.state.newOrganizationUnitMembershipTimespan.to ? this.state.newOrganizationUnitMembershipTimespan.to: ""} />
                                        </td>
                                        <td align="center">
                                            <Form.Check type="checkbox" checked={this.state.newOrganizationUnitMembershipTimespan.isMain} name="isMain" onChange={this.handleChangeToAddOrganizationUnit} size="25"></Form.Check>
                                        </td>
                                        <td align="right">
                                            <Button onClick={this.handleAddOrganizationUnitMembershipTimespans}>Organisation hinzufügen</Button>
                                        </td>
                                    </tr>
                                </tbody>    
                            </table>
                        }
                    </Form.Group>
                    
                    

                    <Form.Group controlId="partTimePercentage">
                        <Form.Label>Teilzeit (in Prozent)</Form.Label>
                        <Form.Control type="text" name="partTimePercentage" value={this.state.employee.partTimePercentage} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="partTimePercentageFrom">
                        <Form.Label>Teilzeit von</Form.Label>
                        <Form.Control type="text" name="partTimePercentageFrom" value={this.state.employee.partTimePercentageFrom} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="partTimePercentageUntil">
                        <Form.Label>Teilzeit bis</Form.Label>
                        <Form.Control type="text" name="partTimePercentageUntil" value={this.state.employee.partTimePercentageUntil} onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group controlId="personnelNumbers">
                        <Form.Label>Personal Nummer</Form.Label>
                        <Form.Control type="text" name="personnelNumbers" value={this.state.employee.personnelNumbers} readOnly onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group controlId="daysOfAbsence">
                        <Form.Label>Urlaubstage</Form.Label>
                        <Form.Control type="number" name="daysOfAbsence" value={this.state.employee.daysOfAbsence} onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group controlId="workingDays">
                        <Form.Label>Arbeitstage</Form.Label>
                        <Form.Check type="checkbox" label="Montag" name="mondayChecked" id="mondayChecked" checked={this.state.mondayChecked} onChange={this.handleChange} />
                        <Form.Check type="checkbox" label="Dienstag" name="tuesdayChecked" id="tuesdayChecked" checked={this.state.tuesdayChecked} onChange={this.handleChange} />
                        <Form.Check type="checkbox" label="Mittwoch" name="wednesdayChecked" id="wednesdayChecked" checked={this.state.wednesdayChecked} onChange={this.handleChange} />
                        <Form.Check type="checkbox" label="Donnerstag" name="thursdayChecked" id="thursdayChecked" checked={this.state.thursdayChecked} onChange={this.handleChange} />
                        <Form.Check type="checkbox" label="Freitag" name="fridayChecked" id="fridayChecked" checked={this.state.fridayChecked} onChange={this.handleChange} />
                        <Form.Check type="checkbox" label="Samstag" name="saturdayChecked" id="saturdayChecked" checked={this.state.saturdayChecked} onChange={this.handleChange} />
                        <Form.Check type="checkbox" label="Sonntag" name="sundayChecked" id="sundayChecked" checked={this.state.sundayChecked} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="workingDaysFrom">
                        <Form.Label>Arbeitstage gültig von</Form.Label>
                        <Form.Control type="date" name="workingDaysFrom" value={this.state.employee.workingDaysFrom} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="workingDaysUntil">
                        <Form.Label>Arbeitstage gültig bis</Form.Label>
                        <Form.Control type="date" name="workingDaysUntil" value={this.state.employee.workingDaysUntil} onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group controlId="actTimeAsEnd">
                        <Form.Check type="checkbox" label="Aktuelle Zeiten als Endzeit" name="actTimeAsEnd" checked={this.state.employee.actTimeAsEnd} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="hidePrivate">
                        <Form.Check type="checkbox" label="Private Daten nicht öffentlich anzeigen" name="hidePrivate" checked={this.state.employee.hidePrivate} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="agbConfirmed">
                        <Form.Check type="checkbox" label="AGB" name="agbConfirmed" onChange={this.handleChange} checked={this.state.employee.agbConfirmed} />
                    </Form.Group>
                </fieldset>}

                <Form.Group>
                    <Button variant="primary" type="submit">
                        Speichern
                        </Button>
                </Form.Group>

            </Form>
        );
    }

}

function mapStateToProps(state) {
    const { organizationUnitReducer, identity } = state;
    const { organizationUnits } = state.organizationUnitReducer;
    return {
        organizationUnitReducer,
        identity,
        organizationUnits
    };
}

export default connect(mapStateToProps)(UserForm);
import React, { Component } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import zxcvbn from 'zxcvbn';
import "./PasswordStrengthMeter.scss";

class PasswordStrengthMeter extends Component {
    constructor(props) {
        super(props);

        this.progressBarColor = this.progressBarColor.bind(this);
        this.strengthLabel = this.strengthLabel.bind(this);
    }

    render() {
        const { password } = this.props;
        const testedResult = zxcvbn(password);
        return (
            <Card className="password-strength-meter">
                <Card.Body>
                    <Card.Text>Passwortstärke</Card.Text>
                    <ProgressBar min={0} max={4} now={testedResult.score} label={this.strengthLabel(testedResult.score)} variant={this.progressBarColor(testedResult.score)} />
                </Card.Body>
            </Card>

        );
    }

    progressBarColor(score) {
        switch (score) {
            case 0:
                return 'danger';
            case 1:
                return 'danger';
            case 2:
                return 'warning';
            case 3:
                return 'warning';
            case 4:
                return 'success';
            default:
                return 'danger';
        }
    }

    strengthLabel(score) {
        switch (score) {
            case 0:
                return '';
            case 1:
                return 'Schwach';
            case 2:
                return 'Ok';
            case 3:
                return 'Gut';
            case 4:
                return 'Stark';
            default:
                return 'Schwach';
        }
    }

}

export default PasswordStrengthMeter;
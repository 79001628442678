import React from 'react';
import { findGetParameter, passwordConfirm } from '../../../../helpers/forms';
import { Jumbotron, Container, Form, Button, Alert, Card, Row, Col } from 'react-bootstrap';
import PasswordStrengthMeter from '../../Shared/forms/PasswordStrengthMeter';
import store from '../../../../store';
import { resetPassword } from '../../../../actions/identityActions';

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: findGetParameter('email'),
            token: findGetParameter('token'),
            password: '',
            passwordConfirm: '',
            passwordError: false,
            passwordErrorInfo: [],
            resetSuccess: false,
            resetError: false

        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;

        if (name === 'password') {
            this.setState({
                passwordError: !passwordConfirm(value, this.state.passwordConfirm)
            });
        }

        if (name === 'passwordConfirm') {
            this.setState({
                passwordError: !passwordConfirm(value, this.state.password)
            });
        }

        this.setState({ [ name ]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { email, token, password } = this.state;

        this.setState({
            resetError: false,
            resetSuccess: false
        });

        if (!this.state.passwordError) {
            store.dispatch(resetPassword(email, token, password)).then((response) => {
                if (!response.ok) {
                    this.setState({
                        resetError: true,
                    });
                    return response.json();
                } else {
                    this.setState({
                        resetSuccess: true
                    });
                }
            }).then((json) => {
                this.setState({
                    passwordError: json.length > 0 ? true : false,
                    passwordErrorInfo: json
                });
            });
        }

    }

    render() {
        return (
            <Jumbotron>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg={6}>
                            <Card>
                                <Card.Body>
                                    <h2>Password zurücksetzen</h2>
                                    <Form name="newPassword" onSubmit={this.handleSubmit}>
                                        <Alert variant="success" show={this.state.resetSuccess}>
                                            Dein Passwort wurde erfolgreich zurückgesetzt. Du kannst dich jetzt mit deinem neuen Passwort anmelden.
                                        </Alert>
                                        <Alert variant="danger" show={this.state.resetError}>
                                            Passwort konnte nicht zurückgesetzt werden.
                                        </Alert>
                                        <Form.Group>
                                            <Form.Label>Neues Passwort</Form.Label>
                                            <Form.Control type="password" name="password" value={this.state.password} required onChange={this.handleChange} isInvalid={this.state.passwordError} />
                                            <Form.Control.Feedback type="invalid">
                                                <ul>
                                                    {this.state.passwordErrorInfo && this.state.passwordErrorInfo.map((error, i) => {
                                                        return <li key={i}>{error.Description}</li>
                                                    })}
                                                </ul>
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <PasswordStrengthMeter password={this.state.password} />
                                        <Form.Group>
                                            <Form.Label>Neues Passwort wiederholen</Form.Label>
                                            <Form.Control type="password" name="passwordConfirm" value={this.state.passwordConfirm} required onChange={this.handleChange} isInvalid={this.state.passwordError} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Button variant="primary" type="submit">
                                                Passwort speichern
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

export default ResetPassword;
import React from 'react';
import { Col, Container, Jumbotron, Row, Card } from 'react-bootstrap';
import LoginForm from './LoginForm/LoginForm';
import { Link } from 'react-router-dom';

class Login extends React.Component {
    render() {
        return (
            <Jumbotron>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg={4}>
                            <Card>
                                <Card.Body>
                                    <h2>Login</h2>
                                    <LoginForm />
                                    <p>
                                        <Link to="RecoverPassword">Passwort vergessen?</Link>
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}

export default Login;
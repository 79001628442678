import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchPersonnelRoles() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return function(dispatch) {
    return fetch(config.backendHost + "/Roles/get/", requestOptions).then(
      response => handleResponse(response)
    );
  };
}

export function updatePersonnelRole(newData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(newData)
  };
  return function(dispatch) {
    return fetch(config.backendHost + "/Roles/Update/", requestOptions).then(
      response => handleResponse(response)
    );
  };
}

export function insertPersonnelRole(newData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(newData)
  };
  return function(dispatch) {
    return fetch(config.backendHost + "/Roles/Insert/", requestOptions).then(
      response => handleResponse(response)
    );
  };
}

export function deletePersonnelRole(newData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(newData)
  };
  return function(dispatch) {
    return fetch(config.backendHost + "/Roles/Delete/", requestOptions).then(
      response => handleResponse(response)
    );
  };
}



export function fetchRolesToEmployee() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
      };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/Roles/getRolesToEmployee", requestOptions
        )
            .then(
              response => handleResponse(response)
            )
    };
}

export function deleteRolesToEmployee(data) {
  console.log(data)
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Roles/deleteRolesToEmployee", requestOptions).then(
              response => handleResponse(response)
            )
    };

}

export function updateRolesToEmployee(data) {
  console.log(data)
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Roles/updateRolesToEmployee", requestOptions).then(
              response => handleResponse(response)
            )
    };

}


  

import React from "react";
import { Toast, Form, Container, Row, Col, Badge } from "react-bootstrap";
import { DateBox, Popup, SelectBox, TextBox, Button, Menu } from 'devextreme-react';
import { formatDate, formatTime } from "./MonthOverviewAllEmployees";
import './AbsenceEntryPopup.scss';
import { editAbsenceEntryRight, editOwnAbsenceEntryRight, deleteAbsenceEntryRight, addOwnAbsenceEntryRight, addAbsenceEntryRight } from "../../../../helpers/Rights";
export class AbsenceEntryPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedContent: 'form'
        }

        this.handleItemClick = this.handleItemClick.bind(this);
        this.popupMaxHeight = this.popupMaxHeight.bind(this);
    }

    render() {
        var editGranted = this.props.identity[ editAbsenceEntryRight.name ];
        var editOwnGranted = this.props.identity[ editOwnAbsenceEntryRight.name ];
        var addOwnGranted = this.props.identity[ addOwnAbsenceEntryRight.name ];
        var addGranted = this.props.identity[ addAbsenceEntryRight.name ];
        var deleteGranted = this.props.identity[ deleteAbsenceEntryRight.name ];

        var statusIsEditableAndExistingEntry = this.props.isEditable && this.props.absenceEntryId !== -1;
        var userLoggedInIsRowUser = false;
        if (this.props.rowUser)
            userLoggedInIsRowUser = this.props.rowUser.userId === this.props.identity.user.Id;


        //Gibt an, ob die Kategorie editierbar ist: Ja, wenn eigene Abwesenheit oder Recht zum Bearbeiten anderer Abwesenheiten und neue Abwesenheit
        var editAbsenceTypeEnabled = (userLoggedInIsRowUser || editGranted) && this.props.absenceEntryId === -1;
        //Gibt an, ob der Status geändert werden darf. Dies ist auch der Fall, wenn die Abwesenheit generell nicht bearbeitbar ist: Ja, wenn eigene Abwesenheit oder Recht zum Bearbeiten anderer Abwesenheiten und Anzahl der AbsenceStates > 1
        var changeStatusEnabled = (userLoggedInIsRowUser || editGranted) && this.props.absenceStates.length > 1;
        //Gibt an, ob der Antrag gelöscht werden kann: Ja, wenn Recht zum Löschen und nicht neue Abwesenheit oder wenn eigene Abwesenheit und Status editierbar aber nicht neu
        var deleteEnabled = (deleteGranted && this.props.absenceEntryId !== -1) || (userLoggedInIsRowUser && statusIsEditableAndExistingEntry);
        //Gibt an, ob man Änderungen speichern kann: Ja, wenn generell editierbar oder entweder Recht zum Bearbeiten von Abwesenheiten und nicht eigene oder Recht zum Bearbeiten eigener Abwesenheiten und eigenen
        var submitEnabled = changeStatusEnabled || ((editGranted && !userLoggedInIsRowUser) || (editOwnGranted && userLoggedInIsRowUser)) || (this.props.absenceEntryId === -1 && ((addOwnGranted && userLoggedInIsRowUser) || (addGranted && !userLoggedInIsRowUser)));
        //Gibt an, ob der Antrag generell editierbar ist: Ja, generell speicherbar und Status editierbar
        var editEnabled = submitEnabled && this.props.isEditable;
        //Gibt an, ob man alle Einträge sehen darf: Ja, wenn eigene Abwesenheit oder wenn Recht andere Abwesenheiten zu bearbeiten
        var showContent = userLoggedInIsRowUser || editGranted;

        var enableHistory = (this.props.absenceEntryId !== -1 && showContent) && this.props.absenceHistories;

        var popupTitle = this.props.absenceType && this.props.absenceStatus ? this.props.absenceType.absenceTypeDisplayString + ' (' + this.props.absenceStatus.statusName + ')' : '';

        return (<Popup className={"absenceEntryPopup"} visible={this.props.popupVisible} onHiding={() => { this.setState({ selectedContent: 'form' }); this.props.hideInfo(); }} closeOnOutsideClick={true} showTitle={true} title={popupTitle} height={'auto'} maxHeight={this.popupMaxHeight} maxWidth={600}>
            <Container style={{ padding: '0px' }}>
                <Row className="align-items-center">
                    <Col>
                        <Menu
                            key={'content'}
                            items={[
                                { text: "Antrag", icon: 'edit', content: "form", selectable: true },
                                { text: "Historie", icon: 'box', content: "history", selectable: true, disabled: !enableHistory }
                            ]}
                            selectByClick={true}
                            onItemClick={this.handleItemClick}

                        />
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        {this.props.absenceStatus !== null &&
                            this.props.absenceType.isLeave === true &&
                            <span className={showContent ? 'remainingDaysOfAbsence' : 'd-none'} style={{ marginRight: "10px" }}>
                                <Badge variant="primary">Resturlaub: {this.props.remainingDaysOfAbsence}</Badge>
                            </span>
                        }
                        {this.props.absenceStatus !== null &&
                            this.props.absenceType.isLeave === true &&
                            <span className={showContent ? 'appliedDaysOfAbsence' : 'd-none'}>
                                <Badge variant="secondary">Beantragt: {this.props.appliedDaysOfAbsence}</Badge>
                            </span>
                        }
                    </Col>
                </Row>
            </Container>
            <Container style={{ overflow: 'auto', border: '1px solid #ddd', paddingTop: '15px', paddingBottom: '15px', marginBottom: '15px' }}>
                {this.state.selectedContent === 'form' &&
                    <form onSubmit={this.props.handleSaveAbsence} className="buttonDialog">
                        <div className={'dx-field'}>
                            <p className={'dx-field-label'}>Kategorie:</p>
                            <SelectBox className={'dx-field-value'} items={this.props.availableAbsenceTypes} displayExpr={'absenceTypeDisplayString'} value={this.props.absenceType} onValueChanged={this.props.onAbsenceTypeChanged} disabled={!editAbsenceTypeEnabled} />
                        </div>
                        <div className={'dx-field'}>
                            <p className={'dx-field-label'}>Status:</p>
                            <SelectBox className={'dx-field-value'} items={this.props.absenceStates} displayExpr={'statusName'} value={this.props.absenceStatus} onValueChanged={this.props.onAbsenceStateChanged} disabled={!changeStatusEnabled} />
                        </div>
                        <div className={'dx-field'}>
                            <p className={'dx-field-label'}>Datum von:</p>
                            <DateBox className={'dx-field-value'} value={this.props.start} onValueChanged={this.props.handleChangeStart} type={this.props.absenceStatus !== null && !this.props.absenceType.requiresTime ? 'date' : 'datetime'} disabled={!editEnabled} />
                        </div>
                        <div className={'dx-field'}>
                            <p className={'dx-field-label'}>Datum bis:</p>
                            <DateBox className={'dx-field-value'} min={this.props.start} value={this.props.end < this.props.start ? this.props.start : this.props.end} onValueChanged={this.props.handleChangeEnd} type={this.props.absenceStatus !== null && this.props.absenceType.requiresTime !== true ? 'date' : 'datetime'} disabled={!editEnabled} />
                        </div>
                        {this.props.absenceStatus !== null &&
                            this.props.absenceType.isLeave &&
                            <div className={showContent ? 'dx-field' : 'dx-field d-none'}>
                                <p className={'dx-field-label'}>Halber Tag:</p>
                                <Form.Check className={'dx-field-value'} type="checkbox" name="isHalfDay" checked={this.props.isHalfDay} onChange={this.props.handleCheckBox} disabled={!editEnabled} />
                            </div>
                        }
                        <div className={(showContent && (deleteEnabled || submitEnabled)) ? 'dx-field' : 'dx-field d-none'}>
                            <p className={'dx-field-label'}>Bemerkung:</p>
                            <TextBox className={'dx-field-value'} onValueChanged={this.props.onCommentChange} disabled={!(deleteEnabled || submitEnabled)} />
                        </div>
                        <div className="buttons">
                            <Button text="Löschen" type="default" onClick={this.props.deleteAbsenceEntry} disabled={!deleteEnabled} />
                            <Button text="Speichern" type="default" useSubmitBehavior={false} onClick={this.props.handleSaveAbsence} disabled={!submitEnabled} style={{ float: 'right' }} />
                        </div>
                    </form>}

                {this.state.selectedContent === 'history' && <Row>
                    <Col>
                        {this.props.absenceHistories ? this.props.absenceHistories.map((item, index) => {
                            return <Toast key={index}><Toast.Header closeButton={false}><strong className='mr-auto'>{item.displayUserName} ({formatDate(item.CreatedOn)}, {formatTime(item.CreatedOn)})</strong></Toast.Header><Toast.Body className='toastBody'>{item.dateTimeFrom ? <div>Anfangszeit: {formatDate(item.dateTimeFrom)}, {formatTime(item.dateTimeFrom)}</div> : ""}{item.dateTimeUntil ? <div>Endzeit: {formatDate(item.dateTimeUntil)}, {formatTime(item.dateTimeUntil)}</div> : ""}{item.comment ? <div>Kommentar: {item.comment}</div> : ""}{item.absenceStatus ? <div>Status: {item.absenceStatus.statusName}</div> : ""}</Toast.Body></Toast>;
                        }) : ""}
                    </Col>
                </Row>}
            </Container>
        </Popup>);
    }

    handleItemClick(e) {
        e.component.selectItem(e.itemElement);
        this.setState({
            selectedContent: e.itemData.content
        });
    }

    popupMaxHeight() {
        return window.innerHeight / 1.5;
    }
}

import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_EMPLOYEES = "REQUEST_EMPLOYEES";
export function requestEmployees() {
  return {
    type: REQUEST_EMPLOYEES
  };
}

export const RECEIVE_EMPLOYEES = "RECEIVE_EMPLOYEES";
export function receiveEmployees(json) {
  return {
    type: RECEIVE_EMPLOYEES,
    allEmployees: json
  };
}

export function fetchAvailableEmployees() {
  return function(dispatch) {
    dispatch(requestEmployees());
    return fetch(config.backendHost + "/Employees/getEmployees", {
      method: "GET",
      headers: authHeader()
    })
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveEmployees(json)));
  };
}

export const REQUEST_EMPLOYEE = "REQUEST_EMPLOYEE";
export function requestEmployee() {
  return {
    type: REQUEST_EMPLOYEE
  };
}

export const RECEIVE_EMPLOYEE = "RECEIVE_EMPLOYEE";
export function receiveEmployee(json) {
  return {
    type: RECEIVE_EMPLOYEE,
    employee: json
  };
}

export function fetchEmployee(employee_id) {
  return function(dispatch) {
    dispatch(requestEmployee());
    return fetch(config.backendHost + "/Employees/details/", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(employee_id)
    })
      .then(response => handleResponse(response))
      .then(json => dispatch(receiveEmployee(json)));
  };
}

export function updateEmployee(employee) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(employee)
  };
  return function(dispatch) {
    return fetch(
      config.backendHost + "/Employees/updateEmployee/",
      requestOptions
    ).then(response => handleResponse(response));
  };
}

export function updateOrganizationUnitMembershipTimespans(
  organizationUnitMembership
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(organizationUnitMembership)
  };
  return function(dispatch) {
    return fetch(
      config.backendHost +
        "/Employees/updateOrganizationUnitMembershipTimespans/",
      requestOptions
    ).then(response => handleResponse(response));
  };
}

export function getUserFromEmployee(user, rightsId, selectedEmployeeUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      authUser: {
        Id: user.Id,
        UserName: user.UserName,
        Token: user.Token
      },
      rightsId: rightsId,
      selectedEmployeeUserId: selectedEmployeeUserId
    })
  };
  return function(dispatch) {
    return fetch(
      config.backendHost + "/Employees/getUserFromEmployee/",
      requestOptions
    ).then(response => handleResponse(response));
  };
}

export function AddMemberToOrganizationUnitMembershipTimespan(
  organizationUnitMemberTimespan
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(organizationUnitMemberTimespan)
  };
  return function(dispatch) {
    return fetch(
      config.backendHost +
        "/Employees/addMemberToOrganizationUnitMembershipTimespan",
      requestOptions
    ).then(response => handleResponse(response));
  };
}

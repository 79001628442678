import { DropDownBox, TreeView } from "devextreme-react";
import React from "react";
import { fetchOrganizationUnits } from "../../../../actions/organizationUnitActions";
import { connect } from "react-redux";
import store from "../../../../store";

class OUFilter extends React.Component {
    constructor(props) {
        super(props);
        this.treeView = null;
        this.dropDownBox = null;
        this.treeViewRender = this.treeViewRender.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);

        this.state = {
          selectedItem: this.props.selectedOrganizationUnit
        }

        this.treeView_onContentReady = this.treeView_onContentReady.bind(this);
      }


    componentWillMount() {
        store.dispatch(fetchOrganizationUnits());
    }

    render() {
        // let ouEntries = [];
        return (
            <DropDownBox
            valueExpr={'organizationUnitId'}
            displayExpr={'organizationUnitName'}
            ref={(ref) => this.dropDownBox = ref}
            value={this.state.selectedItem}
            placeholder={'Wähle aus...'}
            showClearButton={true}
            dataSource={this.props.organizationUnits}
            onValueChanged={this.syncTreeViewSelection}
            contentRender={this.treeViewRender}
            />
        )
    }

    syncTreeViewSelection(e) {
        this.props.treeView_deselectAll(e);

        if (!this.treeView) return;
    
        if (!e.value) {
          this.treeView.instance.unselectAll();
        } else {
          this.treeView.instance.selectItem(e.value);
        }
      }

    treeViewRender() {
        return (
          <TreeView dataSource={this.props.organizationUnits}
            ref={(ref) => this.treeView = ref}
            dataStructure={'plain'}
            keyExpr={'organizationUnitId'}
            parentIdExpr={'parentOrganizationUnitsId'}
            selectionMode={'single'}
            displayExpr={'organizationUnitName'}
            expandedExpr={'expanded'}
            focusStateEnabled={false}
            onItemSelectionChanged={this.props.treeView_itemSelectionChanged}
            onContentReady={this.treeView_onContentReady}
            onItemClick={this.onItemClick}
            selectByClick={true}
          />
        );
      }

      treeView_onContentReady(e) {
        e.component.selectItem(this.state.selectedItem);
      }

      onItemClick() {
          this.dropDownBox.instance.close();
      }
}

function mapStateToProps(state) {
    const {
      organizationUnits
    } = state.organizationUnitReducer;
    return {
      organizationUnits
    };
  }

export default connect(mapStateToProps)(OUFilter);

import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchRights() {
  const requestOptions = {
      method: "GET",
      headers: authHeader()
    }
  return function(dispatch) {
      return fetch(config.backendHost + "/Roles/GetRights/", requestOptions).then(
      response => handleResponse(response)
      );
  };
}

export function fetchSelectedRights(personnelRolesId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(personnelRolesId)
  };
  return function(dispatch) {
      return fetch(config.backendHost + "/Roles/GetSelectedRights/", requestOptions).then(
      response => handleResponse(response)
      );
  };
}

// export function fetchPersonnelRoleRights() {
//   const requestOptions = {
//       method: "GET",
//       headers: authHeader()
//     }
//   return function(dispatch) {
//       return fetch(config.backendHost + "/Roles/GetRoleRights/", requestOptions).then(
//       response => handleResponse(response)
//       );
//   };
// }
    
// export function insertPersonnelRole(newData) {
//     const requestOptions = {
//         method: "POST",
//         headers: authHeader(),
//         body: JSON.stringify(newData),


//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/Roles/Insert/", requestOptions)
//             .then(response => {
//                 if (response.status === 200) {
//                     return { Errors: [], Succeeded: true };
//                 } else {
//                     return response.json();
//                 }
//             }).then(json => {
//                 if (json === "RolleBereitsVorhanden") 
//                 {
//                     notify("Die Rolle ist bereits vorhanden! Bitte andere Bezeichnung verwenden.", "error", 2000);
//                 }
//             });
//     };
// }

// export function deletePersonnelRole(newData) {
//     const requestOptions = {
//         method: "POST",
//         headers: authHeader(),
//         body: JSON.stringify(newData),


//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/Roles/Delete/", requestOptions)
//             .then(response => {
//                 if (response.status === 200) {
//                     return { Errors: [], Succeeded: true };
//                 } else {
//                     return response.json();
//                 }
//             }).then(json => {
//                 if (json === "RolleBereitsVerknuepft") 
//                 {
//                     notify("Die Rolle ist bereits verknüpft und kann deshalb nicht gelöscht werden.", "error", 2000);
//                 }
//                 if (json === "RolleNichtGefunden") 
//                 {
//                     notify("Die Rolle wurde nicht gefunden!", "error", 2000);
//                 }
//         });
//     };
// }

import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  register,
  SET_REGISTRATION_STATUS
} from "../../../../actions/identityActions";
import { userActions } from "../../../../actions/userActions";
import store from "../../../../store";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: "",
        password: generatePassword(),
        role: "Admin"
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.setRole = this.setRole.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetRegistationStatus = this.resetRegistationStatus.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  setRole(event) {
    // const { user } = this.state;
    this.setState({
      user: {
        role: event.target.value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { user } = this.state;
    if (user.username && user.password) {
      store.dispatch(register(user));
    }
  }

  resetRegistationStatus() {
    store.dispatch({
      type: SET_REGISTRATION_STATUS,
      registrationStatus: { Errors: [], Succeeded: null }
    });
    this.setState({
      user: {
        username: "",
        password: generatePassword()
      }
    });
  }

  componentDidUpdate() {
    if (this.props.identity.registrationStatus.Succeeded) {
      setTimeout(this.resetRegistationStatus, 2000);
    }
  }
  componentWillMount() {
    this.props.dispatch(userActions.getAllRoles());
    //this.props.dispatch(userActions.getUserRights(this.props.identity.user));
  }

  render() {
    const { user } = this.state;
    var roles = [];
    if ((this.props.users != null) & (this.props.users.allRoles != null)) {
      roles = this.props.users.allRoles;
    }
    return (
      <Form name="form" onSubmit={this.handleSubmit} autoComplete="off">
        <Alert
          variant="success"
          show={this.props.identity.registrationStatus.status === 200}
        >
          Der neue Benutzer wurde erfolgreich angelegt!
        </Alert>
        <Form.Group controlId="username">
          <Form.Label>E-Mail-Adresse</Form.Label>
          <Form.Control
            type="email"
            name="username"
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            value={user.username}
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="RoleSelect">
          <Form.Label>Rolle</Form.Label>
          <Form.Control
            name="role"
            value={user.role}
            onChange={this.handleChange}
            as="select"
          >
            {roles.map((x, y) => (
              <option key={y}>{x}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Passwort</Form.Label>
          <Form.Control
            type="password"
            name="password"
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            value={user.password}
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Button variant="primary" type="submit">
            Speichern
            {/* {registering && <Spinner className="ml-3" as="div" animation="border" size="sm" role="status" aria-hidden="true" /> } */}
          </Button>
        </Form.Group>
      </Form>
    );
  }
}

function generatePassword() {
  var length = 32,
    charsetLower = "abcdefghijklmnopqrstuvwxyz",
    charsetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    charsetNumeric = "0123456789",
    charsetAlphaNumeric = "!?-_()",
    retVal = "";
  for (var i = 0, n = charsetLower.length; i < length / 4; ++i) {
    retVal += charsetLower.charAt(Math.floor(Math.random() * n));
  }
  for (i = 0, n = charsetUpper.length; i < length / 4; ++i) {
    retVal += charsetUpper.charAt(Math.floor(Math.random() * n));
  }
  for (i = 0, n = charsetNumeric.length; i < length / 4; ++i) {
    retVal += charsetNumeric.charAt(Math.floor(Math.random() * n));
  }
  for (i = 0, n = charsetAlphaNumeric.length; i < length / 4; ++i) {
    retVal += charsetAlphaNumeric.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

function mapStateToProps(state) {
  const { identity, users } = state;
  return {
    identity,
    users
  };
}

export default connect(mapStateToProps)(RegisterForm);

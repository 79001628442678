import {
    REQUEST_ABSENCETYPES,
    RECEIVE_ABSENCETYPES,
    REQUEST_ABSENCEENTRIES,
    RECEIVE_ABSENCEENTRIES,
    REQUEST_ABSENCESTATES,
    RECEIVE_ABSENCESTATES,
    SET_YEAROVERVIEW,
    REQUEST_EMPLOYEEABSENCEENTRIES,
    RECEIVE_EMPLOYEEABSENCEENTRIES,
    REQUEST_REMAININGDAYSOFABSENCE,
    RECEIVE_REMAININGDAYSOFABSENCE,
    REQUEST_DAYSOFABSENCE,
    RECEIVE_DAYSOFABSENCE,
    RECEIVE_UNFINISHEDABSENCEENTRIES
} from "../actions/absenceActions";

const initialState = {
    isFetchingAbsenceTypes: false,
    isFetchingAbsenceStates: false,
    isFetchingAbsenceEntries: false,
    isFetchingEmployeeAbsenceEntries: false,
    availableAbsenceTypes: [],
    allAbsenceEntries: [],
    employeeAbsenceEntries: [],
    availableAbsenceStates: [],
    unfinishedAbsenceEntries: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ABSENCETYPES:
        return Object.assign({}, state, {
            isFetchingAbsenceTypes: true
        });
        case RECEIVE_ABSENCETYPES:
        return Object.assign({}, state, {
            isFetchingAbsenceTypes: false,
            availableAbsenceTypes: action.availableAbsenceTypes
        });
        case REQUEST_ABSENCESTATES:
        return Object.assign({}, state, {
            isFetchingAbsenceStates: true
        });
        case RECEIVE_ABSENCESTATES:
        return Object.assign({}, state, {
            isFetchingAbsenceStates: false,
            availableAbsenceStates: action.availableAbsenceStates
        });
        case REQUEST_ABSENCEENTRIES:
        return Object.assign({}, state, {
            isFetchingAbsenceEntries: true
        });
        case RECEIVE_ABSENCEENTRIES:
        return Object.assign({}, state, {
            isFetchingAbsenceEntries: false,
            allAbsenceEntries: action.allAbsenceEntries
        });
        case SET_YEAROVERVIEW:
        return Object.assign({}, state, {
            selectedYear: action.selectedYear
        });
        case REQUEST_EMPLOYEEABSENCEENTRIES:
        return Object.assign({}, state, {
            isFetchingEmployeeAbsenceEntries: true,
        });
        case RECEIVE_EMPLOYEEABSENCEENTRIES:
        return Object.assign({}, state, {
            isFetchingEmployeeAbsenceEntries: false,
            employeeAbsenceEntries: action.employeeAbsenceEntries
        });
        case REQUEST_REMAININGDAYSOFABSENCE:
        return Object.assign({}, state, {
            isFetchingRemainigDaysOfAbsence: true
        });
        case RECEIVE_REMAININGDAYSOFABSENCE:
        return Object.assign({}, state, {
            isFetchingRemainigDaysOfAbsence: false
        });
        case REQUEST_DAYSOFABSENCE:
        return Object.assign({}, state, {
            isFetchingDaysOfAbsence: true
        });
        case RECEIVE_DAYSOFABSENCE:
        return Object.assign({}, state, {
            isFetchingDaysOfAbsence: false
        });
        case RECEIVE_UNFINISHEDABSENCEENTRIES:
        return Object.assign({}, state, {
            unfinishedAbsenceEntries: action.unfinishedAbsenceEntries
        });
    default:
      return state;
  }
};
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { DateBox} from 'devextreme-react';
import { connect } from 'react-redux';
import "../../core/Icons/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const trash = {
    fontSize: "25",
    display: "inline",
    margin: "auto auto auto auto"
  };

class RolesToEmployeeRows extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            data: {
                applicationUsersId: this.props.data.applicationUsersId,
                organizationUnitsId:  this.props.data.organizationUnitsId,
                oldOrganizationUnitsId: this.props.data.organizationUnitsId,
                personnelRolesId: this.props.data.personnelRolesId,
                oldPersonnelRolesId: this.props.data.personnelRolesId,
                from: this.props.data.from,
                to: this.props.data.to,
                ApplicationUser: this.props.data.ApplicationUser
            },
            neuerEintrag: this.props.neuerEintrag
        }
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event)
    {
        var {name, value} = event.target;
        var data = this.state.data
        this.setState({
            data: {
                ...data,
                [ name ]: value
            }
        })
    }
    render()
    {
        return (
            <tr>
                <td>
                    {!this.props.neuerEintrag ?
                    <Form.Control key="applicationUsersId" type="text" name="applicationUsersId" disabled value={this.state.data.ApplicationUser.employee.familyName + ' ' + this.state.data.ApplicationUser.employee.firstName}></Form.Control>
                    :
                    <Form.Control key="applicationUsersId" as="select" name="applicationUsersId" value={this.state.data.applicationUsersId} onChange={this.handleChange} required displayvalue={this.state.data.applicationUsersId}>
                        {this.props.user.map((user) => {
                                    return (<option key={'ou' + user.Id} value={user.Id}>{user.employee ? (user.employee.firstName + ' ' + user.employee.familyName) : user.UserName}</option>)
                                })}
                    </Form.Control>
                    }
                    
                </td>
                <td>
                    <Form.Control key="organizationUnitsId" as="select" name="organizationUnitsId" value={this.state.data.organizationUnitsId} onChange={this.handleChange} required>
                        {this.props.organizationUnits.map((ou) => {
                                    return (<option key={'ou' + ou.organizationUnitId} value={ou.organizationUnitId}>{ou.organizationUnitName}</option>)
                                })}
                    </Form.Control>
                </td>
                <td>
                    <Form.Control key="personnelRolesId" as="select" name="personnelRolesId" value={this.state.data.personnelRolesId} onChange={this.handleChange} required>
                            {this.props.roles.map((p) => {
                                        return (<option key={'p' + p.personnelRolesId} value={p.personnelRolesId}>{p.name}</option>)
                                    })}
                    </Form.Control>
                </td>
                <td>
                    <DateBox value={this.state.data.from} onValueChanged={this.handleChange} type={'date'} required/>
                </td>
                <td>
                    <DateBox value={this.state.data.to} onValueChanged={this.handleChange} type={'date'} />
                </td>
                <td>
                    <Button variant="success" type="button" onClick={() => this.props.onClick("save", this.state.data, this.state.neuerEintrag)}>
                        <FontAwesomeIcon icon="save" style={trash} />
                    </Button>                        
                </td>
                <td>
                    <button className="btn btn-danger" type="button" onClick={() => this.props.onClick("delete", this.state.data, this.state.neuerEintrag)}>
                        <FontAwesomeIcon icon="trash" style={trash} />
                    </button>
                </td>
            </tr>
                
        )
    }

}

function mapStateToProps(state) {
    const { organizationUnitReducer, identity } = state;
    const { organizationUnits } = state.organizationUnitReducer;
    return {
        organizationUnitReducer,
        identity,
        organizationUnits
    };
}
export default connect(mapStateToProps)(RolesToEmployeeRows);
import { combineReducers } from 'redux';

import { users } from './usersReducer';
import absenceReducer from './absenceReducer';
import identity from './identityReducer';
import organizationUnitReducer from './organizationUnitReducer';
import employeeReducer from './employeeReducer';

const rootReducer = combineReducers({
    users,
    identity,
    absenceReducer,
    employeeReducer,
    organizationUnitReducer
});

export default rootReducer;
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel, faUser, faSignOutAlt, faCalendarAlt, faCalendar, faUserPlus, faAngleLeft, faAngleRight, faTrash, faSave, faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faStroopwafel);
library.add(faUser);
library.add(faSignOutAlt);
library.add(faCalendarAlt);
library.add(faCalendar);
library.add(faUserPlus);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faTrash);
library.add(faSave);
library.add(faEnvelope);

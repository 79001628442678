import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import store from '../../../store';
import { fetchSelectedRights, fetchRights } from '../../../actions/configurationActions.js';
import { fetchPersonnelRoles } from '../../../actions/personnelRolesActions.js';
import { TreeList, Column, Selection } from 'devextreme-react/tree-list';
import { SelectBox } from 'devextreme-react';

class Configuration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rights: [],
            RoleRights: [],
            treeVisibility: false,
            selectedPersonnelRole: null,
            selectedRights: []
        };

        this.handleSaveRights = this.handleSaveRights.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {
        store.dispatch(fetchPersonnelRoles()).then(json => this.setState({ roles: json, selectedPersonnelRole: json[0].personnelRolesId }));
        store.dispatch(fetchRights()).then(json => this.setState({ rights : json }));
        //store.dispatch(fetchSelectedRights(this.state.personnelRolesId)).then(json => this.setState({ selectedRights : json }));
    }

    render() {
        return (
            <Jumbotron>
                <Container>
                    <SelectBox  
                        dataSource={this.state.roles} 
                        displayExpr = {'name'} 
                        valueExpr = {'personnelRolesId'}
                        onValueChanged = {this.handleChange}
                    />
                </Container>
                <Container>
                <TreeList id={'RoleRights'}
                    dataSource={this.state.rights}
                    keyExpr={'id'}
                    selectedRowKeys={this.state.selectedRights}
                    parentIdExpr={'parent.id'}
                    visible={this.state.treeVisibility} 
                    onSelectionChanged={this.handleSaveRights}
                >
                    <Selection recursive={true} mode={'multiple'} />
                    <Column dataField={'description'} caption={'Beschreibung'} />
                    <Column dataField={'id'} visible={false} />

                </ TreeList>
                </Container>

            </Jumbotron>    
      );
    }
    
    handleSaveRights() {
        console.log("hi");
    }

    handleChange(e)
    {   
        store.dispatch(fetchSelectedRights(e.value))
            .then(json => this.setState({ selectedRights : json }))
            .then(response => {this.setState({ treeVisibility: true })});
    }    
}


//     handleInit(e) {
//         e.component.beginCustomLoading();
//         store.dispatch(fetchPersonnelRoles()).then(json => {
//             this.setState({ roles: json });
//             e.component.endCustomLoading();
//         })
//     }

//     handleUpdate(e) {
//         e.component.beginCustomLoading();
//         store.dispatch(updatePersonnelRole(e.data)).then(
//             response => {this.handleInit(e);
//             e.component.endCustomLoading()
//             });
//     }

//     handleRemove(e) {
//         e.component.beginCustomLoading();
//         store.dispatch(deletePersonnelRole(e.data)).then(
//             response => {this.handleInit(e);
//             e.component.endCustomLoading();
            
//         });
//     }


//     handleInsert(e) {
//         e.component.beginCustomLoading();
//         store.dispatch(insertPersonnelRole(e.data)).then(
//             response =>  {this.handleInit(e);
//             e.component.endCustomLoading();}
//         );
//     }

// }

export default Configuration;
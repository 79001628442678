import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import OUFilter from "../../Shared/oufilter/OUFilter";
import { isWeekend, iconStyleArrowLeft, iconStyleArrowRight } from './MonthOverviewAllEmployees';
import MonthOverviewAllEmployeesTableRow from './MonthOverviewAllEmployeesTableRow';

class MonthOverviewAllEmployeesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialDate ist immer entweder der Zeitpunkt zu dem die Komponente geöffnet wurde oder +-1 Monat, falls über die Monate navigiert wurde
      initialDate: this.props.date ? moment(this.props.date) : moment()
    };
    this.onLastMonthClick = this.onLastMonthClick.bind(this);
    this.onNextMonthClick = this.onNextMonthClick.bind(this);
  }

  render() {
    let topLegendDay = [];
    let legend = [];
    let legend2 = [];
    let legend3 = [];
    let data = [];
    let colCount = 0;
    // Setzt die Startzeit des betrachteten Zeitraums auf den drittletzten Tag des letzten Monats
    let startDate = new moment(this.state.initialDate)
      .subtract(1, "month")
      .endOf("month")
      .subtract(2, "days");
    // Füllt die Arrays Tageszahlen/-abkürzungen für alle Tage des betrachteten Zeitraums
    for (let i = 0, day = new moment(this.state.initialDate)
      .subtract(1, "month")
      .endOf("month")
      .subtract(2, "days"); i < this.state.initialDate.daysInMonth() + 6; i++ , day = day.add(1, "day")) {
      let dayEntry = [];
      dayEntry.number = day.format("DD");
      dayEntry.isWeekend = isWeekend(new Date(day));
      dayEntry.name = day.format("ddd");
      topLegendDay.push(dayEntry);
    }
    // Erstellt die Tableheader mit den 3 Monaten des betrachteten Zeitraums
    var e = new moment(startDate);
    colCount = this.state.initialDate.daysInMonth() + 6;
    legend.push(<th key={e.format("MMM YY")} colSpan={3} onClick={this.onLastMonthClick}>
      <FontAwesomeIcon icon="angle-left" style={iconStyleArrowLeft} />
      {e.format("MMM YY")}
    </th>);
    e.add(1, "months");
    legend.push(<th key={e.format("MMM YY")} colSpan={e.daysInMonth()}>
      {e.format("MMM YY")}
    </th>);
    e.add(1, "months");
    legend.push(<th key={e.format("MMM YY")} colSpan={3} onClick={this.onNextMonthClick}>
      {e.format("MMM YY")}
      <FontAwesomeIcon icon="angle-right" style={iconStyleArrowRight} />
    </th>);
    e.add(1, "months");
    // Pusht die Tageszahlen/-abkürzungen als Tableheader der 2ten und 3ten Ebene in die Tabelle
    for (let i = 0; i < colCount; i++) {
      legend2.push(<th key={i} style={{ backgroundColor: topLegendDay[ i ].isWeekend ? "#aaa" : "#fff" }}>{topLegendDay[ i ].number}</th>);
      legend3.push(<th key={i} style={{ backgroundColor: topLegendDay[ i ].isWeekend ? "#aaa" : "#fff" }}>{topLegendDay[ i ].name}</th>);
    }
    // Erstellt für jeden Mitarbeiter(=Row) eine neue Zeile und übergibt alle nötigen Props
    for (let i = 0; i < this.props.rowCount; i++) {
      data.push(<MonthOverviewAllEmployeesTableRow rowIndex={i} colCount={colCount} initialDate={this.state.initialDate} key={i} legend={this.props.data[ i ].firstName} rowUser={this.props.data[ i ]} values={this.props.data[ i ].absenceEntries} user={this.props.user} availableAbsenceTypes={this.props.availableAbsenceTypes} availableAbsenceStates={this.props.availableAbsenceStates} allAbsenceEntries={this.props.allAbsenceEntries} onCellClick={this.props.onCellClick} />);
    }

    return (<div>
      <OUFilter selectedOrganizationUnit={this.props.selectedOrganizationUnit} treeView_itemSelectionChanged={this.props.treeView_itemSelectionChanged} treeView_deselectAll={this.props.treeView_deselectAll} />
      <Table striped bordered hover className="responsive">
        <thead>
          <tr>
            <th key={1} />
            {legend}
          </tr>
          <tr>
            <th key={2} />
            {legend2}
          </tr>
          <tr>
            <th key={3} />
            {legend3}
          </tr>
        </thead>
        <tbody>{data}</tbody>
      </Table>
    </div>);
  }

  onLastMonthClick() {
    this.setState({
      initialDate: this.state.initialDate.subtract(1, "months")
    });
  }
  onNextMonthClick() {
    this.setState({
      initialDate: this.state.initialDate.add(1, "months")
    });
  }
}

function mapStateToProps(state) {
  const { user, modifyAbsences } = state.identity;
  return {
    user,
    modifyAbsences
  };
}

export default connect(mapStateToProps)(MonthOverviewAllEmployeesTable);
